import React, { useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHasRoles } from "react-simple-rbac";
import NiceModal from "@ebay/nice-modal-react";
import { toggleNavigation } from "../../Slices/uiSlice";
import StyledNavigation from "./style";
import {
  StyledButtonNegativeSmall,
  StyledButtonOutlinedSmall
} from "../../Style/buttons";
import { StyledBadgeNegative } from "../../Style";
import { logOut } from "../../Slices/authSlice";
import { selectCurrentUser } from "../../Slices/authSlice";
import { useLazyGetProfileQuery } from "../../Slices/apiProfileSlice";
import { setPaywallViewed } from "../../Slices/generalSlice";
import { ReactComponent as WaveIcon } from "../../Assets/wave-square-solid.svg";
// import { ReactComponent as TasksIcon } from "../../Assets/tasks-alt.svg";
import { ReactComponent as UserIcon } from "../../Assets/user-alt.svg";
import { ReactComponent as BookmarkSolid } from "../../Assets/bookmark-solid.svg";
// import { ReactComponent as PaymentsIcon } from "../../Assets/credit-card-front.svg";
// import { ReactComponent as BellIcon } from "../../Assets/bell.svg";
// import { ReactComponent as CogIcon } from "../../Assets/cog.svg";
import { HapticFeedback } from "../../utils";

const Navigation = () => {
  const navigate = useNavigate();

  const isProUser = useHasRoles(["Pro User"]);

  const user = useSelector(selectCurrentUser);

  const [getProfile] = useLazyGetProfileQuery();

  const dispatch = useDispatch();

  const [signOutLoading, setSignOutLoading] = useState(false);

  return (
    <StyledNavigation>
      <ul>
        <li>
          <h1>Tamarin</h1>
        </li>
        {/* <li className="mt-a">
          <Link
            to={"/"}
            onClick={() => {
              HapticFeedback();
              dispatch(toggleNavigation(false));
            }}
          >
            <TasksIcon /> Filter Recipes
          </Link>
        </li> */}
        <li className="mt-a">
          <Link
            to={"/"}
            onClick={() => {
              HapticFeedback();
              dispatch(toggleNavigation(false));
              NiceModal.show("info-modal", {
                heading: "Voice Assistant",
                description: (
                  <>
                    Cook a dish from start to finish with the help of voice
                    commands and voice instructions. After enabling the
                    microphone you can say the following commands:
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        gap: "10px",
                        marginTop: "15px",
                        whiteSpace: "nowrap",
                        flexWrap: "wrap"
                      }}
                    >
                      <StyledBadgeNegative>READ STEP</StyledBadgeNegative>
                      <StyledBadgeNegative>PREVIOUS STEP</StyledBadgeNegative>
                      <StyledBadgeNegative>NEXT STEP</StyledBadgeNegative>
                      <StyledBadgeNegative>
                        SHOW INGREDIENTS
                      </StyledBadgeNegative>
                      <StyledBadgeNegative>
                        HIDE INGREDIENTS
                      </StyledBadgeNegative>
                    </div>
                  </>
                ),
                hideDismiss: true,
                submitLabel: "Ok",
                onSubmit: () => {
                  HapticFeedback();
                  NiceModal.remove("info-modal");
                }
              });
            }}
          >
            <WaveIcon /> Voice Assistant
          </Link>
        </li>
        <li>
          <Link
            to={"/favorites"}
            onClick={() => {
              HapticFeedback();
              dispatch(toggleNavigation(false));
            }}
          >
            <BookmarkSolid />
            Favorites
          </Link>
        </li>

        {user && (
          <li>
            <Link
              to={"/profile"}
              onClick={() => {
                HapticFeedback();
                dispatch(toggleNavigation(false));
              }}
            >
              <UserIcon />
              Profile
            </Link>
          </li>
        )}
        {/* <li>
          <Link to={"/payment-and-billing"}>
            <PaymentsIcon />
            Payment & Billing
          </Link>
        </li>
        <li>
          <Link to={"/notifications"}>
            <BellIcon />
            Notifications
          </Link>
        </li>
        <li className="mt-a">
          <Link to={"/settings"}>
            <CogIcon />
            Settings
          </Link>
        </li> */}
        {user && !isProUser && (
          <li style={{ width: "100%" }}>
            <StyledButtonOutlinedSmall
              onClick={() => {
                HapticFeedback();
                dispatch(setPaywallViewed(false));
                getProfile();
              }}
              className="mt-25 mb-15 w-100"
              style={{
                minHeight: "44px"
              }}
            >
              Upgrade to PRO
            </StyledButtonOutlinedSmall>
          </li>
        )}
        {!user && (
          <li style={{ width: "100%" }}>
            <StyledButtonOutlinedSmall
              loading={signOutLoading.toString()}
              onClick={() => {
                HapticFeedback();
                dispatch(toggleNavigation(false));
                navigate("/signup");
              }}
              className="mt-25 mb-15 w-100"
              style={{
                minHeight: "44px"
              }}
            >
              SIGN UP
            </StyledButtonOutlinedSmall>
          </li>
        )}
        {user && (
          <li>
            <StyledButtonNegativeSmall
              loading={signOutLoading.toString()}
              onClick={() => {
                HapticFeedback();
                setSignOutLoading(true);
                setTimeout(() => {
                  dispatch(toggleNavigation(false));
                  dispatch(logOut());
                }, 1500);
              }}
              className="mt-25 mb-15 w-100"
            >
              Sign Out
            </StyledButtonNegativeSmall>
          </li>
        )}
      </ul>
    </StyledNavigation>
  );
};

export default Navigation;
