import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";

export const apiIngredients = createApi({
  reducerPath: "apiIngredients",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  keepUnusedDataFor: 600,
  endpoints: (builder) => ({
    getIngredients: builder.query({
      // query: () => `/ingredients`,
      query: (data) => {
        const query = qs.stringify(data);

        return `/ingredients${query ? `?${query}` : ""}`;
      },
      providesTags: ["Ingredients"]
    })
  })
});

export const resetApiState = apiIngredients.util.resetApiState;

export const { useGetIngredientsQuery, useLazyGetIngredientsQuery } =
  apiIngredients;
