import styled from "styled-components";

const StyledList = styled.ul`
  margin: 0 0 10px;
  padding: 0;

  li {
    display: flex;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 14px;
    line-height: 50px;
    text-align: left;
    text-transform: capitalize;
    color: #454545;

    span {
      font-weight: 500;
      color: #404852;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        min-width: 25px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #454545;
        padding: 0px 13px;
        border-radius: 8px;
        background-color: #f5f7fb;
      }
    }

    small {
      margin-left: 3px;
    }
  }
`;

const StyledMealList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 32px;
    padding: 10px 0;

    &:before {
      opacity: 0.4;
      content: "";
      position: absolute;
      left: 6%;
      right: 6%;
      bottom: -16px;
      height: 1px;
      background-color: #dbdbe7;
    }

    &:last-child {
      margin: 0 0 16px;

      &:before {
        display: none;
      }
    }

    .recipe-image {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .recipe-info {
      padding: 0 0 0 14px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-family: "Nanum Gothic", sans-serif;
        font-size: 12px;
        line-height: 1.6;
        text-align: left;
        color: #454545;
        margin: 0 0 12px;
        text-transform: capitalize;
      }

      .recipe-meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .recipe-meta-item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-right: 10px;
          font-family: "Nanum Gothic", sans-serif;
          font-size: 11px;
          line-height: 15px;
          text-align: left;
          color: #454545;

          svg {
            width: 12px;
            height: 12px;
            margin-right: 4px;
            fill: #454545;
          }
        }
      }
    }
  }
`;

const StyledGroceryList = styled(StyledMealList)`
  li {
    padding: 12px 0;

    &:before {
      left: 0;
      right: 0;
    }

    h2 {
      font-family: "Nanum Gothic", sans-serif;
      font-size: 12px;
      line-height: 1;
      text-align: left;
      color: #454545;
      margin: 0 0 12px;
      text-transform: capitalize;
    }
  }
`;

export { StyledList, StyledMealList, StyledGroceryList };
