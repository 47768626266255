import React from "react";
import { Formik, Form as FormikForm } from "formik";

const Form = (props) => {
  let children = props.children;
  return (
    <Formik {...props}>{() => <FormikForm>{children}</FormikForm>}</Formik>
  );
};

export { Form };
