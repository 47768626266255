import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiUserFilters = createApi({
  reducerPath: "apiUserFilters",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  keepUnusedDataFor: 600,
  endpoints: (builder) => ({
    getUserFilters: builder.query({
      query: () => `/user-filters`,
      providesTags: ["UserFilters"]
    }),
    getUserFilter: builder.query({
      query: (id) => `/user-filters/${id}`,
      providesTags: ["UserFilter"]
    }),
    createUserFilter: builder.mutation({
      query: (data) => ({
        url: `/user-filters`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["UserFilter"]
    }),
    updateUserFilter: builder.mutation({
      query: ({ id, data } = {}) => {
        return {
          url: `/user-filters/${id}`,
          method: "PUT",
          body: data
        };
      },
      invalidatesTags: ["UserFilters", "UserFilter"],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiUserFilters.util.updateQueryData(
            "getUserFilters",
            undefined,
            (draft) => {
              return draft.filter((item) => item.id !== id);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          dispatch(apiUserFilters.util.invalidateTags(["Favorites"]));
        }
      }
    }),
    deleteUserFilter: builder.mutation({
      query: (id) => {
        return {
          url: `/user-filters/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: ["UserFilters", "UserFilter"],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiUserFilters.util.updateQueryData(
            "getUserFilters",
            undefined,
            (draft) => {
              return draft.filter((item) => item.id !== id);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          dispatch(
            apiUserFilters.util.invalidateTags(["UserFilters", "UserFilter"])
          );
        }
      }
    })
  })
});

export const {
  useGetUserFiltersQuery,
  useLazyGetUserFiltersQuery,
  useGetUserFilterQuery,
  useCreateUserFilterMutation,
  useUpdateUserFilterMutation
} = apiUserFilters;
