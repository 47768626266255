import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { changeUiConfig } from "../../Slices/uiSlice";
import { setNavigatedToRecipeFromIndex } from "../../Slices/generalSlice";
import {
  StyledBadgeNegative,
  StyledCard,
  StyledButtonSmall
} from "../../Style";
import { ReactComponent as SoupIcon } from "../../Assets/soup.svg";
import { ReactComponent as BasketIcon } from "../../Assets/shopping-basket.svg";
import { HapticFeedback } from "../../utils";

const Recipe = ({
  index,
  recipe,
  loading,
  minHeight,
  sendUserActivity,
  signupRequired
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [imageLoading, setImageLoading] = useState(true);

  const isLoading = !recipe || loading || imageLoading;

  const {
    _id,
    time,
    title,
    servings,
    ingredients,
    ingredientsList,
    verified,
    cover: {
      formats: {
        large = null,
        medium = null,
        small = null,
        thumbnail = null
      } = {}
    } = {}
  } = recipe || {};

  return (
    <StyledCard
      onClick={() => {
        if (!isLoading && !signupRequired) {
          dispatch(
            changeUiConfig({
              showHeader: true,
              mainActionType: "back",
              headerType: "title",
              header: "Let's Cook",
              secondaryActionType: "recipeOptions",
              showFooter: false
            })
          );
          dispatch(setNavigatedToRecipeFromIndex(index));
          sendUserActivity({
            type: "navigate_to_recipe",
            recipe: _id
          });
          navigate(`/recipes/${_id}`);
          HapticFeedback();
        }
      }}
      style={{
        padding: 0
      }}
      loading={isLoading.toString()}
      id={_id}
    >
      <div className="card-image">
        <img
          src={`${process.env.REACT_APP_API_URL}${
            medium?.url || small?.url || large?.url || thumbnail?.url
          }`}
          alt="Recipe"
          draggable={false}
          loading="lazy"
          style={{
            opacity: isLoading && 0,
            minHeight: minHeight ?? ""
          }}
          onLoad={(e) => setImageLoading(false)}
        />
        {!signupRequired && (
          <div
            className="card-image-meta"
            style={isLoading ? { justifyContent: "space-between" } : {}}
          >
            {isLoading ? (
              <Skeleton width={120} height={30} />
            ) : (
              <StyledBadgeNegative>
                {(time * 60) / 60} Minutes
              </StyledBadgeNegative>
            )}
          </div>
        )}
      </div>
      {!signupRequired && (
        <div className="card-details">
          <h2>{isLoading ? <Skeleton width={200} height={20} /> : title}</h2>
          <div className="card-meta">
            <div
              className="card-meta-item"
              style={isLoading ? { padding: "0" } : {}}
            >
              {isLoading ? (
                <Skeleton width={120} height={26} />
              ) : (
                <>
                  <SoupIcon />
                  {`${servings} Servings`}
                </>
              )}
            </div>

            <div
              className="card-meta-item"
              style={isLoading ? { padding: "0" } : {}}
            >
              {isLoading ? (
                <Skeleton width={120} height={26} />
              ) : (
                <>
                  <BasketIcon />
                  {`${
                    verified ? ingredientsList?.length : ingredients?.length
                  } Ingredients`}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {signupRequired && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            position: "absolute",
            top: 0,
            bottom: 0,
            zIndex: 3,
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: "0 15px 15px",
            color: "#ffff",
            backdropFilter: "saturate(200%) blur(4px)"
          }}
          className="mb-0"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div style={{ width: "calc(100% - 140px)" }}>
              <h2
                className="mt-0 mb-2 ml-0"
                style={{
                  color: "#ffff",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                Unlock {title}
              </h2>
              <p className="mt-0 mb-0" style={{ fontSize: "12px" }}>
                by Signing Up
              </p>
            </div>
            <StyledButtonSmall
              className="mb-0"
              style={{ width: "120px" }}
              onClick={() => {
                HapticFeedback();
                navigate("/signup");
              }}
            >
              SIGN UP
            </StyledButtonSmall>
          </div>
        </div>
      )}
    </StyledCard>
  );
};

export default Recipe;
