import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { HapticFeedback } from "../../utils";
import { endOfToday, startOfDay, addDays, isToday } from "date-fns";

const StyledDatePicker = styled.div`
  .react-datepicker {
    width: 100%;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border: 0;
  }

  .react-datepicker__month-container {
    float: none;
    width: 100%;

    .react-datepicker__header {
      background-color: transparent;
      border: 0;
      padding: 0;

      .react-datepicker__current-month {
        font-size: 14px;
        line-height: 2;
      }
      .react-datepicker__header__dropdown
        .react-datepicker__header__dropdown--scroll {
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
        margin-bottom: 0;
        padding: 10px 0;

        .react-datepicker__day-name {
          margin: 0;
          width: 42px;
          line-height: 42px;
        }
      }
    }

    .react-datepicker__month {
      margin: 0;

      .react-datepicker__week {
        display: flex;
        justify-content: space-around;
        padding: 10px 0;

        &:last-child {
          padding-bottom: 0;
        }

        .react-datepicker__day {
          margin: 0;
          width: 42px;
          line-height: 42px;
          color: #454545;

          &.react-datepicker__day--disabled {
            color: #ccc;
          }

          &:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled):hover {
            border-radius: 12px;
            background-color: #f2f6f7;
          }

          &--selected {
            color: #fff;
            border-radius: 28px;
            background-color: #65c18c !important;
          }
        }
      }
    }
  }

  .react-datepicker__time-container {
    width: auto;
    height: 100%;
    /* margin-left: 20px; */
    border: 0;

    .react-datepicker__header {
      background-color: transparent;
      border: 0;
      padding: 0;

      .react-datepicker-time__header {
        font-size: 14px;
        line-height: 2;
      }
    }

    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 75px;

        .react-datepicker__time-list {
          padding-top: 10px;

          .react-datepicker__time-list-item {
            margin: 0 0 20px;
            padding: 0;
            height: 42px;
            line-height: 42px;
            color: #454545;

            &.react-datepicker__time-list-item--disabled {
              color: #ccc;
            }

            &:not(.react-datepicker__time-list-item--selected):not(.react-datepicker__time-list-item--disabled):hover {
              border-radius: 12px;
              background-color: #f2f6f7;
            }

            &--selected {
              color: #fff;
              border-radius: 28px;
              background-color: #65c18c !important;
              font-weight: 400;
            }

            &:first-child {
              /* margin-top: 10px; */
            }

            &:last-child {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
`;

const DatePickerComp = (props) => {
  return (
    <StyledDatePicker>
      <DatePicker
        selected={props?.startDate}
        peekNextMonth={false}
        timeIntervals={15}
        minDate={new Date()}
        // maxDate={addDays(new Date(), 2)}
        minTime={
          isToday(props?.startDate) ? new Date() : startOfDay(props?.startDate)
        }
        maxTime={endOfToday()}
        autoComplete={false}
        // highlightDates={[addDays(new Date(), 1), addDays(new Date(), 2)]}
        // isClearable={true}
        {...props}
        onChange={(date) => {
          HapticFeedback();
          props.onChange(date);
        }}
      />
    </StyledDatePicker>
  );
};

export default DatePickerComp;
