import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import * as Yup from "yup";
import BaseModal from "./BaseModal";
import { Field, Switch } from "../";
import {
  StyledForm,
  StyledFormGroup,
  StyledFormGroupSwitch
} from "../../Style";
import { HapticFeedback, PostMessage } from "../../utils";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

const SaveFilter = NiceModal.create(() => {
  const saveFilterRef = useRef();

  const modal = useModal();

  const [sendUserActivity] = useSendUserActivityMutation();

  useEffect(() => {
    PostMessage({
      type: "requestAppTrackingPermissions"
    });
  }, []);

  return (
    <BaseModal
      onSubmit={() => {
        saveFilterRef?.current?.submitForm();
      }}
      onDismiss={() => {
        HapticFeedback();
        modal.remove();
      }}
      visible={modal.visible}
    >
      <Formik
        innerRef={saveFilterRef}
        onSubmit={async (values) => {
          sendUserActivity({
            type: "filter_saved",
            data: values
          });
          modal.resolve(values);
          modal.remove();
        }}
        initialValues={{
          name: "",
          schedule: false,
          repeatInterval: 1,
          repeatType: "weeks",
          days: []
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name required.")
        })}
      >
        {({ values }) => {
          return (
            <FormikForm
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <StyledForm>
                <StyledFormGroup outlined>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Name"
                    inputMode="text"
                    onClick={() => HapticFeedback()}
                  />
                </StyledFormGroup>
                <div
                  style={{
                    padding: "0 20px",
                    borderRadius: "6px",
                    border: "1px solid #dbdbe7"
                  }}
                >
                  <StyledFormGroupSwitch
                    style={{ border: 0, margin: "10px 0" }}
                  >
                    <h4>Schedule</h4>
                    <Switch name="schedule" />
                  </StyledFormGroupSwitch>
                  {values.schedule && (
                    <>
                      <div
                        style={{
                          borderTop: "1px solid #dbdbe7",
                          paddingTop: "10px",
                          paddingBottom: "30px"
                        }}
                      >
                        <h4>Repeat every</h4>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px"
                          }}
                        >
                          <StyledFormGroup outlined style={{ width: "80px" }}>
                            <Field
                              name="repeatInterval"
                              type="number"
                              inputMode="numeric"
                              onClick={() => HapticFeedback()}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup outlined style={{ flex: 1 }}>
                            <Field
                              name="repeatType"
                              type="select"
                              inputMode="select"
                              values={[
                                {
                                  value: "weeks",
                                  label: "weeks"
                                },
                                {
                                  value: "days",
                                  label: "days"
                                }
                              ]}
                              onClick={() => HapticFeedback()}
                            />
                          </StyledFormGroup>
                        </div>
                        {values?.repeatType === "weeks" && (
                          <>
                            <h4>On Week Days</h4>
                            <FieldArray
                              name="days"
                              render={(arrayHelpers) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "20px",
                                    justifyContent: "start"
                                  }}
                                >
                                  {days?.map((value, key) => (
                                    <Chip
                                      key={key}
                                      label={value}
                                      onClick={() => {
                                        HapticFeedback();
                                        values.days.includes(value)
                                          ? arrayHelpers.remove(
                                              values.days.indexOf(value)
                                            )
                                          : arrayHelpers.push(value);
                                      }}
                                      variant={
                                        values.days.includes(value)
                                          ? "solid"
                                          : "outlined"
                                      }
                                    />
                                  ))}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </StyledForm>
            </FormikForm>
          );
        }}
      </Formik>
    </BaseModal>
  );
});

export default SaveFilter;
