import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";

export const apiProfile = createApi({
  reducerPath: "apiProfile",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => "/users/me",
      providesTags: ["Profile"]
    }),
    rewardUser: builder.query({
      query: (data) => {
        const query = qs.stringify(data);

        return `/users/me/reward${query ? `?${query}` : ""}`;
      },
      invalidatesTags: ["Profile"]
    }),
    chargeUserPoints: builder.query({
      query: (data) => {
        const query = qs.stringify(data);

        return `/users/me/charge-points${query ? `?${query}` : ""}`;
      },
      invalidatesTags: ["Profile"]
    }),
    changeAvatar: builder.mutation({
      query: (file) => ({
        url: "/upload",
        method: "POST",
        body: file
      })
    }),
    // updateProfile: builder.mutation({
    //   query: ({ id, body }) => ({
    //     url: `/users/${id}`,
    //     method: "PUT",
    //     body: body
    //   })
    // }),
    updateProfile: builder.mutation({
      async queryFn(_arg, { getState }, _extraOptions, fetchWithBQ) {
        const state = getState();
        state?.auth?.user?.id &&
          (await fetchWithBQ({
            url: `/users/${state?.auth?.user?.id}`,
            method: "PUT",
            body: _arg.body
          }));
      },
      invalidatesTags: ["Profile"]
    }),
    deleteProfile: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "delete"
      })
    })
  })
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useLazyRewardUserQuery,
  useLazyChargeUserPointsQuery,
  useChangeAvatarMutation,
  useUpdateProfileMutation,
  useDeleteProfileMutation
} = apiProfile;
