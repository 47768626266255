import styled from "styled-components";

const StyledPaywall = styled.div`
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 24px;
  padding-top: calc(32px + var(--safe-area-inset-top));
  padding-bottom: calc(32px + var(--safe-area-inset-bottom));
  background-color: #fff;
  backdrop-filter: saturate(180%) blur(2px);

  .skip-button {
    position: absolute;
    top: calc(32px + var(--safe-area-inset-top));
    right: 24px;
  }

  h2 {
    /* font-family: "Ubuntu", sans-serif; */
    font-size: 26px;
    line-height: 26px;
    text-align: left;
    color: #404852;
    text-align: left;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  h3 {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #404852;
    text-align: left;
    font-weight: bolder;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  h6 {
    font-size: 26px;
    font-weight: 300;
    font-family: inherit;
    line-height: 1;
}
  }

  p {
    font-family: "Nanum Gothic", sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #adb3bf;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .dialog-card {
    width: 100%;
    /* height: 100%;
    padding: 32px 24px;
    border-radius: 16px;
    background: #ffffff;
    border: 1px solid #65c18c;
    margin-top: 10px;
    margin-bottom: 20px; */
  }

  .feature {
    width: 100%;
    padding: 18px 24px;
    padding: 24px 12px;
    /* border-radius: 6px; */
    background: #ffffff;
    border-bottom: 1px solid #f5f7fb;
    /* margin-top: 10px; */
    /* margin-bottom: 20px; */
  }
`;

export default StyledPaywall;
