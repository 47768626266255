import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Hammer from "hammerjs";
import { toggleNavigation } from "../../Slices/uiSlice";

const AppSwipteHandler = ({ children }) => {
  let el = useRef(null);

  let hammerRef = useRef(null);

  const [init, setInit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!init && el.current) {
      hammerRef.current = Hammer(el.current);
      hammerRef.current.on("swipeleft", () => {
        dispatch(toggleNavigation(false));
      });
      hammerRef.current.on("swiperight", () => {
        dispatch(toggleNavigation(true));
      });
    }
    setInit(true);
  }, [init, el, dispatch]);

  return (
    <div ref={el} style={{ height: "100%" }}>
      {children}
    </div>
  );
};

export default AppSwipteHandler;
