import styled, { css } from "styled-components";

const StyledCard = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
  padding: 14px;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);

  .card-image {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    min-height: 30px;
    display: block;
    overflow: hidden;
    /* border-radius: 10px; */
    background: #fff;
    /* box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3); */

    img {
      min-height: 280px;
      max-height: 280px;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      filter: saturate(130%);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* background-color: rgb(17 17 17 / 15%); */
    }

    .card-image-meta {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      top: 0;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .card-details {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    background-color: rgb(69 69 69 / 30%);
    border-radius: 0 0 10px 10px;
    backdrop-filter: saturate(200%) blur(10px);
  }

  h2 {
    font-family: "Ubuntu", sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #ffffff;
    margin: 0 0 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
    /* text-transform: uppercase; */
  }

  .card-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin: 0 0 15px; */

    .card-meta-item {
      display: flex;
      align-items: center;
      font-family: "Nanum Gothic", sans-serif;
      font-size: 12px;
      line-height: 1;
      height: 28px;
      text-align: left;
      color: #43927d;
      background: #ffffff;
      border-radius: 28px;
      padding: 0 12px;

      &:last-child {
        margin-left: auto;
      }

      svg {
        width: auto;
        height: 14px;
        margin-right: 6px;
        fill: #43927d;
      }
    }

    /* > button {
      margin-left: auto;
    } */
  }

  ${({ loading }) =>
    loading === "true" &&
    css`
      .card-details {
        background-color: transparent !important;
      }
    `}
`;

const StyledCardDetails = styled(StyledCard)`
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  /* height: calc(100vh - 86px - 77px - 15px); */
  margin: 0;
  padding-bottom: 56px;
  padding-bottom: calc(56px + var(--safe-area-inset-bottom));
`;

const StyledCardAccent = styled.div`
  width: 100%;
  margin: 0 0 10px;
  padding: 24px;
  border-radius: 6px;
  background: linear-gradient(
    -45deg,
    #8b94ff 0%,
    #454545 50.7%,
    #545cfe 71.07%,
    #7d83ff 100%
  );
  box-shadow: 0px 3px 10px rgba(83, 91, 254, 0.3);
`;

export { StyledCard, StyledCardDetails, StyledCardAccent };
