import React, { Fragment } from "react";
import Lottie from "lottie-react";
import Slider from "react-slick";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StyledWelcome from "./style";
import { selectCurrentUser } from "../../Slices/authSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import { selectPlatformOS } from "../../Slices/platformSlice";
import { setSkippedWelcomeScreen } from "../../Slices/generalSlice";
import {
  StyledLink,
  StyledButton,
  StyledButtonDark,
  StyledButtonBlack,
  StyledButtonWhite
} from "../../Style";
import { HapticFeedback, PostMessage } from "../../utils";
import TamarinLogo from "../../Assets/tamarin-logo.jpg";
import VUILottie from "../../Assets/vui-lottie.json";
import RecipesLottie from "../../Assets/recipes-lottie.json";
import GroceriesLottie from "../../Assets/groceries-lottie";
import { ReactComponent as AppleLogo } from "../../Assets/apple-logo.svg";
import { ReactComponent as GoogleLogo } from "../../Assets/google-logo.svg";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Welcome = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const platformOS = useSelector(selectPlatformOS);

  const [sendUserActivity] = useSendUserActivityMutation();

  return user ? (
    <Navigate to="/" />
  ) : (
    <Fragment>
      <StyledWelcome>
        <div
          style={{
            position: "relative",
            marginTop: "auto",
            marginBottom: "auto",
            // border: "5px solid #454545",
            borderRadius: "16px",
            padding: "20px 0 45px"
          }}
        >
          <Slider {...settings}>
            <div>
              <Lottie
                animationData={VUILottie}
                loop
                style={{ height: "200px" }}
              />
              <h1
                style={{
                  fontSize: "18px",
                  lineHeight: 1.8,
                  textAlign: "center",
                  textTransform: "uppercase"
                }}
              >
                Voice commands
              </h1>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  lineHeight: 1.8
                }}
              >
                The voice assistant will help you without requiring to much
                interaction with the phone.
              </p>
            </div>
            <div>
              <Lottie
                animationData={RecipesLottie}
                loop
                style={{ height: "200px" }}
              />
              <h1
                style={{
                  fontSize: "18px",
                  lineHeight: 1.8,
                  textAlign: "center",
                  textTransform: "uppercase"
                }}
              >
                Delightful recipes
              </h1>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  lineHeight: 1.8
                }}
              >
                Set out to discover and prepare each day new and delightful
                recipes by so changing your day.
              </p>
            </div>
            <div>
              <Lottie
                animationData={GroceriesLottie}
                loop
                style={{ height: "200px" }}
              />
              <h1
                style={{
                  fontSize: "18px",
                  lineHeight: 1.8,
                  textAlign: "center",
                  textTransform: "uppercase"
                }}
              >
                Groceries List
              </h1>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  lineHeight: 1.8
                }}
              >
                Save each of the required ingredients ahead of time and use the
                list when grocery shopping.
              </p>
            </div>
          </Slider>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "auto"
          }}
        >
          <h1
            style={{
              fontSize: "40px",
              lineHeight: 1.2
            }}
          >
            <small>Welcome to</small>
            <br />
            TAMARIN
          </h1>
          <img
            src={TamarinLogo}
            style={{
              width: "auto",
              height: "80px",
              display: "block",
              marginTop: "10px",
              borderRadius: "16px",
              objectFit: "cover"
            }}
          />
        </div>
        <div className="columns is-mobile">
          <div className="column">
            <StyledButton
              className="mt-20 mb-0"
              onClick={() => {
                HapticFeedback();
                navigate("/signup");
              }}
            >
              Sign up
            </StyledButton>
          </div>
          <div className="column">
            <StyledButtonDark
              className="mt-20 mb-0"
              onClick={() => {
                HapticFeedback();
                navigate("/login");
              }}
            >
              Log in
            </StyledButtonDark>
          </div>
        </div>
        {window?.ReactNativeWebView && platformOS === "ios" && (
          <div className="columns is-mobile">
            <div className="column">
              <StyledButtonBlack
                onClick={(e) => {
                  e.stopPropagation();
                  HapticFeedback();
                  PostMessage({
                    type: "continueWithApple"
                  });
                }}
              >
                <AppleLogo />
                Continue with Apple
              </StyledButtonBlack>
            </div>
          </div>
        )}
        {window?.ReactNativeWebView && platformOS === "android" && (
          <div className="columns is-mobile">
            <div className="column">
              <StyledButtonWhite
                onClick={(e) => {
                  e.stopPropagation();
                  HapticFeedback();
                  PostMessage({
                    type: "continueWithGoogle"
                  });
                }}
              >
                <GoogleLogo />
                Continue with Google
              </StyledButtonWhite>
            </div>
          </div>
        )}
        <StyledLink
          to={"/"}
          className="mt-2 mb-0"
          style={{ textAlign: "center" }}
          onClick={(e) => {
            e.preventDefault();
            HapticFeedback();
            dispatch(setSkippedWelcomeScreen(true));
            sendUserActivity({
              type: "skipped_auth"
            });
            navigate("/");
          }}
        >
          I'll do it later
        </StyledLink>
      </StyledWelcome>
    </Fragment>
  );
};

export default Welcome;
