import React, { Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import NiceModal from "@ebay/nice-modal-react";
import StyledSignUp from "./style";
import { Form, Field } from "../../Components";
import {
  StyledCard,
  StyledButton,
  StyledButtonIcon,
  StyledLink,
  StyledForm,
  StyledFormGroup,
  StyledButtonWhite,
  StyledButtonBlack
} from "../../Style";
import { useSignUpMutation } from "../../Slices/apiAuthSlice";
import { setCredentials, selectCurrentUser } from "../../Slices/authSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import { selectPlatformOS } from "../../Slices/platformSlice";
import { HapticFeedback, PostMessage } from "../../utils";
import { ReactComponent as ChevronLeftIcon } from "../../Assets/chevron-left.svg";
import TamarinLogo from "../../Assets/tamarin-logo.jpg";
import { ReactComponent as AppleLogo } from "../../Assets/apple-logo.svg";
import { ReactComponent as GoogleLogo } from "../../Assets/google-logo.svg";

const SignUpSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^[A-Z][a-z]*( [A-Z][a-z]*)*$/, "Full name must be capitalized.")
    .required("Full name required"),
  email: Yup.string().email("Email invalid.").required("Email required."),
  password: Yup.string()
    .min(8, "Password too short.")
    .required("Password required.")
});

const SignUp = () => {
  const user = useSelector(selectCurrentUser);

  const platformOS = useSelector(selectPlatformOS);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [signUp, { isLoading }] = useSignUpMutation();

  const [sendUserActivity] = useSendUserActivityMutation();

  return user && user.emailConfirmed ? (
    <Navigate to="/" />
  ) : (
    <Fragment>
      <StyledSignUp>
        <StyledButtonIcon
          style={{
            width: "40px",
            lineHeight: 1,
            boxShadow: "0px 2px 10px rgb(173 179 191 / 15%)",
            borderRadius: "12px"
          }}
          onClick={() => {
            HapticFeedback();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </StyledButtonIcon>
        <img
          src={TamarinLogo}
          style={{
            width: "180px",
            height: "180px",
            display: "block",
            borderRadius: "16px",
            margin: "auto auto 0"
          }}
        />
        <h1
          style={{
            marginTop: "auto",
            marginBottom: "30px",
            fontSize: "42px"
          }}
        >
          Sign up
        </h1>
        <Form
          initialValues={{
            username: "",
            email: "",
            password: ""
          }}
          validationSchema={SignUpSchema}
          onSubmit={async (values) => {
            try {
              values.firstName = values.username.split(" ")[0];
              values.lastName = values.username.split(" ")[1];
              const { data, error } = await signUp(values);
              if (data) {
                dispatch(setCredentials(data));
                PostMessage({
                  type: "sendFirebaseAnalyticsEvent",
                  value: {
                    name: "sign_up"
                  }
                });
                sendUserActivity({
                  type: "sign_up"
                });
                // if (data.user.emailConfirmed) {
                //   navigate("/");
                // } else {
                //   navigate(`/confirm-email-address?email=${data.user.email}`);
                // }
                navigate("/");
              }
              if (
                error &&
                error?.data?.message[0]?.messages[0]?.message ===
                  "Email is already taken."
              ) {
                NiceModal.show("info-modal", {
                  heading: "We have a little problem.",
                  description: "Email is already taken.",
                  hideSubmit: true,
                  dismissLabel: "Close",
                  warning: true
                });
              }

              if (error && error?.status === "FETCH_ERROR") {
                NiceModal.show("info-modal", {
                  heading: "Oops!",
                  description:
                    "Something went wrong. We are not sure yet and will examine this case. Try again.",
                  hideSubmit: true,
                  dismissLabel: "OK"
                });
              }
            } catch (err) {
              console.log(err);
            }
          }}
          style={{ height: "100%" }}
        >
          <StyledCard className="mb-0">
            <StyledForm>
              <StyledFormGroup>
                <Field name="username" type="text" placeholder="Full Name" />
              </StyledFormGroup>
              <StyledFormGroup>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  inputMode="email"
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <Field name="password" type="password" placeholder="Password" />
              </StyledFormGroup>
            </StyledForm>
          </StyledCard>
          <div className="columns is-mobile">
            <div className="column">
              <StyledButton
                className="mt-20 mb-0"
                loading={isLoading.toString()}
                onClick={() => HapticFeedback()}
              >
                Sign up
              </StyledButton>
            </div>
          </div>
          {window?.ReactNativeWebView && platformOS === "ios" && (
            <div className="columns is-mobile">
              <div className="column">
                <StyledButtonBlack
                  onClick={(e) => {
                    e.stopPropagation();
                    HapticFeedback();
                    PostMessage({
                      type: "continueWithApple"
                    });
                  }}
                >
                  <AppleLogo />
                  Continue with Apple
                </StyledButtonBlack>
              </div>
            </div>
          )}
          {window?.ReactNativeWebView && platformOS === "android" && (
            <div className="columns is-mobile">
              <div className="column">
                <StyledButtonWhite
                  onClick={(e) => {
                    e.stopPropagation();
                    HapticFeedback();
                    PostMessage({
                      type: "continueWithGoogle"
                    });
                  }}
                >
                  <GoogleLogo />
                  Continue with Google
                </StyledButtonWhite>
              </div>
            </div>
          )}
          <div className="signup-action">
            <StyledLink
              className="ml-5"
              to={"/login"}
              onClick={() => HapticFeedback()}
            >
              You have an account? Log in
            </StyledLink>
          </div>
          {/* <div className="signup-action">
            Do you have an account?
            <StyledLink
              className="ml-5"
              to={"/signin"}
              onClick={() => HapticFeedback()}
            >
              Sign In
            </StyledLink>
          </div> */}
          {/* <hr className="mt-10" />
          <StyledButtonDark>Sign In with Facebook</StyledButtonDark> */}
        </Form>
      </StyledSignUp>
    </Fragment>
  );
};

export default SignUp;
