import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiProfile } from "./apiProfileSlice";
import { setCredentials } from "./authSlice";

export const apiAuth = createApi({
  reducerPath: "apiAuth",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/local",
        method: "POST",
        body: credentials
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, getCacheEntry, dispatch }
      ) {
        await cacheDataLoaded;

        const { data } = getCacheEntry();

        await dispatch(setCredentials(data));

        await dispatch(apiProfile.endpoints.getProfile.initiate());
      }
    }),
    aogOauthAuthorize: builder.mutation({
      query: (body) => ({
        url: "/aog-oauth-authorize",
        method: "POST",
        body: body
      })
    }),
    signUp: builder.mutation({
      query: (credentials) => ({
        url: "/auth/local/register",
        method: "POST",
        body: credentials
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, getCacheEntry, dispatch }
      ) {
        await cacheDataLoaded;

        const { data } = getCacheEntry();

        await dispatch(setCredentials(data));

        await dispatch(apiProfile.endpoints.getProfile.initiate());
      }
    }),
    sendEmailConfirmation: builder.mutation({
      query: (body) => ({
        url: "/auth/send-email-confirmation",
        method: "POST",
        body: body
      })
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: body
      })
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: body
      }),
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, getCacheEntry, dispatch }
      ) {
        await cacheDataLoaded;

        const { data } = getCacheEntry();

        await dispatch(setCredentials(data));

        await dispatch(apiProfile.endpoints.getProfile.initiate());
      }
    }),
    appleAuth: builder.query({
      query: ({ code, firstName, lastName }) =>
        `/auth/apple/callback?access_token=${code}${
          firstName ? `&firstName=${firstName}` : ""
        }${lastName ? `&lastName=${lastName}` : ""}`,
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, getCacheEntry, dispatch }
      ) {
        await cacheDataLoaded;

        const { data } = getCacheEntry();

        await dispatch(setCredentials(data));

        await dispatch(apiProfile.endpoints.getProfile.initiate());
      }
    }),
    googleAuth: builder.query({
      query: ({ code, firstName, lastName }) =>
        `/auth/google/callback?access_token=${code}${
          firstName ? `&firstName=${firstName}` : ""
        }${lastName ? `&lastName=${lastName}` : ""}`,
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, getCacheEntry, dispatch }
      ) {
        await cacheDataLoaded;

        const { data } = getCacheEntry();

        await dispatch(setCredentials(data));

        await dispatch(apiProfile.endpoints.getProfile.initiate());
      }
    })
  })
});

export const {
  useLoginMutation,
  useAogOauthAuthorizeMutation,
  useSignUpMutation,
  useSendEmailConfirmationMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLazyAppleAuthQuery,
  useLazyGoogleAuthQuery
} = apiAuth;
