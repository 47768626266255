import styled from "styled-components";

const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 0;
  transition: all 400ms ease-in-out;
  background-color: #ffffff;
  transform: scale(1) translate3d(0, 0, 0);
  will-change: transform;
  box-shadow: 0px 0px 2px rgb(69 69 69 / 40%);
`;

export default StyledWrapper;
