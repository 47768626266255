import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  font-family: "Nanum Gothic", sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #454545;
  text-decoration: none;
`;

export { StyledLink };
