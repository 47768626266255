import styled from "styled-components";

const StyledBadge = styled.div`
  min-height: 28px;
  width: auto;
  border: 1px solid #65c18c;
  font-family: "Nanum Gothic", sans-serif;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  padding: 0 12px;
  border-radius: 6px;
  background-color: #65c18c;
  display: flex;
  align-items: center;
`;

const StyledBadgeOutlined = styled(StyledBadge)`
  color: #65c18c;
  background-color: transparent;
`;

const StyledBadgeNegative = styled(StyledBadge)`
  color: #65c18c;
  border-color: #f5f7fb;
  background-color: #f5f7fb;
`;

export { StyledBadge, StyledBadgeOutlined, StyledBadgeNegative };
