import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiCourses = createApi({
  reducerPath: "apiCourses",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  keepUnusedDataFor: 600,
  endpoints: (builder) => ({
    getCourses: builder.query({
      query: () => `/courses`,
      providesTags: ["Courses"]
    })
  })
});

export const { useGetCoursesQuery } = apiCourses;
