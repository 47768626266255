import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  AppSwipteHandler,
  Wrapper,
  Layout,
  Header,
  Content,
  AuthRouteLayout
} from "../../Components";
import { setCredentials } from "../../Slices/authSlice";
import { changeUiConfig } from "../../Slices/uiSlice";

const AogOauthAuthorization = React.lazy(() => import("./OauthAuthorization"));
const AogRecipes = React.lazy(() => import("./Recipes"));

const AogRouteLayout = ({ children }) => (
  <AppSwipteHandler>
    <Wrapper>
      <Header
        style={{
          paddingTop: "40px",
          paddingLeft: "40px",
          paddingRight: "40px",
          paddingBottom: "30px"
        }}
      />
      <Layout>
        <Content
          className="pb-0"
          style={{
            paddingTop: "109px",
            paddingLeft: "40px",
            paddingRight: "40px"
          }}
        >
          {children}
        </Content>
      </Layout>
    </Wrapper>
  </AppSwipteHandler>
);

const AogRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeUiConfig({
        showHeader: true,
        headerType: "user"
      })
    );

    window.interactiveCanvas &&
      window.interactiveCanvas.getHeaderHeightPx().then((height) => {
        console.log("interactiveCanvasHeight", height);

        // height &&
        //   dispatch(
        //     changeUiConfig({
        //       showHeader: true,
        //       headerType: "user",
        //       customInsetTop: height
        //     })
        //   );

        const callbacks = {
          onUpdate: (data) => {
            try {
              // const dataEntry = data[0];
              // const scene = dataEntry.scene
              //   ? dataEntry.scene
              //   : dataEntry.google
              //   ? dataEntry.google.intent.name
              //   : null;
              // const params = dataEntry.params ? dataEntry.params : null;
              console.log("interactiveCanvasData", data);
              dispatch(setCredentials(data[0]));
            } catch (e) {
              // do nothing, when no command is sent or found
            }
          }
        };

        window.interactiveCanvas.ready(callbacks);
      });
  }, [dispatch]);

  return (
    <Routes>
      {/* <Route index element={<Navigate to="/home" />} /> */}
      <Route
        exact
        path="/oauth-authorization"
        element={
          <Suspense fallback={<>...</>}>
            <AuthRouteLayout>
              <AogOauthAuthorization />
            </AuthRouteLayout>
          </Suspense>
        }
      />
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<>...</>}>
            <AogRouteLayout>
              <AogRecipes />
            </AogRouteLayout>
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AogRoutes;
