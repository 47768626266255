import React, { Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import StyledForgotPassword from "./style";
import { Form, Field } from "../../Components";
import {
  StyledCard,
  StyledButton,
  StyledButtonIcon,
  StyledForm,
  StyledFormGroup
} from "../../Style";
import { useForgotPasswordMutation } from "../../Slices/apiAuthSlice";
import { selectCurrentUser } from "../../Slices/authSlice";
import { HapticFeedback } from "../../utils";
import { ReactComponent as ChevronLeftIcon } from "../../Assets/chevron-left.svg";
import TamarinLogo from "../../Assets/tamarin-logo.jpg";
import NiceModal from "../../Components/Modals";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Email invalid.").required("Email required.")
});

const ForgotPassword = () => {
  const user = useSelector(selectCurrentUser);

  const navigate = useNavigate();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  return user ? (
    <Navigate to="/" />
  ) : (
    <Fragment>
      <StyledForgotPassword>
        <StyledButtonIcon
          style={{
            width: "40px",
            lineHeight: 1,
            boxShadow: "0px 2px 10px rgb(173 179 191 / 15%)",
            borderRadius: "12px"
          }}
          onClick={() => {
            HapticFeedback();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </StyledButtonIcon>
        <img
          src={TamarinLogo}
          style={{
            width: "180px",
            height: "180px",
            display: "block",
            borderRadius: "16px",
            margin: "auto auto 0"
          }}
        />
        <h1
          style={{
            marginTop: "auto",
            marginBottom: "30px",
            fontSize: "42px"
          }}
        >
          🔑 Reset
        </h1>
        <Form
          initialValues={{
            email: ""
          }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={async (values) => {
            try {
              const { data, error } = await forgotPassword(values);
              if (data) {
                NiceModal.show("info-modal", {
                  heading: "Congratulations.",
                  description:
                    "We sent you a e-mail that includes a reset link.",
                  submitLabel: "Close",
                  hideDismiss: true
                });
              }
              if (
                error &&
                error?.data?.message[0]?.messages[0]?.message ===
                  "This email does not exist."
              ) {
                NiceModal.show("info-modal", {
                  heading: "We have a little problem.",
                  description: "This email does not exist.",
                  dismissLabel: "Close",
                  warning: true
                });
              }

              if (error && error?.status === "FETCH_ERROR") {
                NiceModal.show("info-modal", {
                  heading: "Oops!",
                  description:
                    "Something went wrong. We are not sure yet and will examine this case. Try again.",
                  dismissLabel: "OK",
                  warning: true
                });
              }
            } catch (err) {
              console.log(err);
            }
          }}
          style={{ height: "100%" }}
        >
          <StyledCard className="mb-0">
            <StyledForm>
              <StyledFormGroup>
                <Field name="email" type="text" placeholder="Email" />
              </StyledFormGroup>
            </StyledForm>
          </StyledCard>
          <StyledButton className="mt-20 mb-0" loading={isLoading}>
            Send
          </StyledButton>
        </Form>
      </StyledForgotPassword>
    </Fragment>
  );
};

export default ForgotPassword;
