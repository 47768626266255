import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Normalize } from "styled-normalize";
import { RBACProvider } from "react-simple-rbac";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Style/bulma.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Store } from "./store";
import GlobalStyle from "./Style/global";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload)
};

Object.freeze(Emitter);

window.GlobalEventEmitter = Emitter;

const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          minHeight: "30px",
          backgroundColor: "#454545",
          border: "1px solid #454545",
          color: "#ffffff",
          fontSize: "12px",
          borderRadius: "30px",
          fontFamily: "Nanum Gothic",
          fontWeight: "500",
          textTransform: "capitalize",
          "&.MuiChip-clickable:hover": {
            color: "#ffffff",
            backgroundColor: "#454545",
            boxShadow: "none"
          },
          "&.MuiChip-clickable:active": {
            color: "#ffffff",
            backgroundColor: "#454545",
            boxShadow: "none"
          }
        },
        outlined: {
          backgroundColor: "transparent !important",
          border: "1px solid #dbdbe7 !important",
          color: "#404852 !important",
          fontSize: "12px",
          borderRadius: "30px",
          fontFamily: "Nanum Gothic"
        },
        label: {
          padding: "0 10px"
        }
      }
    }
  }
});

const startApp = () =>
  ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <Normalize />
        <GlobalStyle />
        <RBACProvider>
          <App />
        </RBACProvider>
      </ThemeProvider>
    </Provider>
  );

if (window.cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      window.StatusBar && window.StatusBar.styleDefault();
      startApp();
    },
    false
  );
} else {
  startApp();
}

serviceWorker.register();
