import React from "react";
import { NavLink as Link } from "react-router-dom";
import { useSelector } from "react-redux";
import StyledFooter from "./style";
import { StyledButtonIcon } from "../../Style/buttons";
import { ReactComponent as HomeIcon } from "../../Assets/home-lg-alt.svg";
import { ReactComponent as ShoppingBasketIcon } from "../../Assets/shopping-basket.svg";
import { ReactComponent as CalendarIcon } from "../../Assets/calendar-alt.svg";
import { ReactComponent as UserIcon } from "../../Assets/user-alt.svg";
import { HapticFeedback } from "../../utils";
import { selectCurrentUser } from "../../Slices/authSlice";

const Footer = () => {
  const user = useSelector(selectCurrentUser);

  const { showFooter } = useSelector((state) => state.ui.config);

  return showFooter ? (
    <StyledFooter>
      <ul>
        <li>
          <StyledButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              HapticFeedback();
            }}
          >
            <Link to={"/"}>
              <HomeIcon />
            </Link>
          </StyledButtonIcon>
        </li>
        <li>
          <StyledButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              HapticFeedback();
            }}
          >
            <Link to={"/shopping-lists"}>
              <ShoppingBasketIcon />
            </Link>
          </StyledButtonIcon>
        </li>
        <li>
          <StyledButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              HapticFeedback();
            }}
          >
            <Link to={"/planner"}>
              <CalendarIcon />
            </Link>
          </StyledButtonIcon>
        </li>
        {user && (
          <li>
            <StyledButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                HapticFeedback();
              }}
            >
              <Link to={"/profile"}>
                <UserIcon />
              </Link>
            </StyledButtonIcon>
          </li>
        )}
      </ul>
    </StyledFooter>
  ) : null;
};

export default Footer;
