import { configureStore } from "@reduxjs/toolkit";
import { apiAuth } from "./Slices/apiAuthSlice";
import { apiCookingSession } from "./Slices/apiCookingSessionSlice";
import { apiProfile } from "./Slices/apiProfileSlice";
import { apiRecipes } from "./Slices/apiRecipesSlice";
import { apiUserActivity } from "./Slices/apiUserActivitySlice";
import { apiGroceries } from "./Slices/apiGroceriesSlice";
import { apiFavorites } from "./Slices/apiFavoritesSlice";
import { apiPlannerSlice } from "./Slices/apiPlannerSlice";
import { apiPayments } from "./Slices/apiPaymentsSlice";
import { apiUserFilters } from "./Slices/apiUserFiltersSlice";
import { apiDiets } from "./Slices/apiDietsSlice";
import { apiDishTypes } from "./Slices/apiDishTypesSlice";
import { apiIngredients } from "./Slices/apiIngredientsSlice";
import { apiCourses } from "./Slices/apiCoursesSlice";
import { apiCuisines } from "./Slices/apiCuisinesSlice";
import auth from "./Slices/authSlice";
import filter from "./Slices/filterSlice";
import platform from "./Slices/platformSlice";
import product from "./Slices/productSlice";
import recipes from "./Slices/recipeSlice";
import ui from "./Slices/uiSlice";
import general from "./Slices/generalSlice";

export const Store = configureStore({
  reducer: {
    [apiAuth.reducerPath]: apiAuth.reducer,
    [apiCookingSession.reducerPath]: apiCookingSession.reducer,
    [apiProfile.reducerPath]: apiProfile.reducer,
    [apiRecipes.reducerPath]: apiRecipes.reducer,
    [apiUserActivity.reducerPath]: apiUserActivity.reducer,
    [apiGroceries.reducerPath]: apiGroceries.reducer,
    [apiFavorites.reducerPath]: apiFavorites.reducer,
    [apiPlannerSlice.reducerPath]: apiPlannerSlice.reducer,
    [apiPayments.reducerPath]: apiPayments.reducer,
    [apiUserFilters.reducerPath]: apiUserFilters.reducer,
    [apiDiets.reducerPath]: apiDiets.reducer,
    [apiDishTypes.reducerPath]: apiDishTypes.reducer,
    [apiIngredients.reducerPath]: apiIngredients.reducer,
    [apiCourses.reducerPath]: apiCourses.reducer,
    [apiCuisines.reducerPath]: apiCuisines.reducer,
    auth,
    filter,
    platform,
    product,
    recipes,
    ui,
    general
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apiAuth.middleware,
      apiCookingSession.middleware,
      apiProfile.middleware,
      apiRecipes.middleware,
      apiUserActivity.middleware,
      apiGroceries.middleware,
      apiFavorites.middleware,
      apiPlannerSlice.middleware,
      apiPayments.middleware,
      apiUserFilters.middleware,
      apiDiets.middleware,
      apiIngredients.middleware,
      apiCourses.middleware,
      apiCuisines.middleware
    ])
});
