import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiFavorites = createApi({
  reducerPath: "apiFavorites",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getFavorites: builder.query({
      query: ({ start, limit, recipe } = {}) =>
        `/user-recipe-collections${recipe ? recipe : ""}${
          start ? `?_start=${start}&_limit=${limit}` : ""
        }`,
      providesTags: ["Favorites"]
    }),
    getFavoritesCount: builder.query({
      query: () => `/user-recipe-collections/count`
    }),
    addToFavorites: builder.mutation({
      query: (data) => ({
        url: "/user-recipe-collections",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Favorites", "FavoriteRecipe"]
    }),
    removeFavorite: builder.mutation({
      query: ({ id }) => ({
        url: `/user-recipe-collections/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Favorites", "FavoriteRecipe"],
      async onQueryStarted({ id, filter }, { dispatch, queryFulfilled }) {
        const patchResult =
          filter &&
          dispatch(
            apiFavorites.util.updateQueryData(
              "getFavorites",
              filter,
              (draft) => {
                return draft.filter((recipe) => recipe.id !== id);
              }
            )
          );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          dispatch(apiFavorites.util.invalidateTags(["Favorites"]));
        }
      }
    }),
    favoriteRecipe: builder.query({
      query: (recipeId) =>
        `/user-recipe-collections/favorite-recipe/${recipeId}`,
      providesTags: ["FavoriteRecipe"]
    })
  })
});

export const {
  useGetFavoritesQuery,
  useGetFavoritesCountQuery,
  useAddToFavoritesMutation,
  useRemoveFavoriteMutation,
  useFavoriteRecipeQuery
} = apiFavorites;
