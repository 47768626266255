import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
        font-size: 10px;
    }

    body {
        height: 100%;
        text-rendering: optimizeLegibility;
        font-family: "Nanum Gothic", sans-serif;
        font-size: 12px;
        line-height: 16px;
        color: #454545;
        background-color: #ffffff;
    }

    #root {
        height: 100%;
    }

    *,
    *:before,
    *:after {
        outline: 0;
        box-sizing: border-box;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    button,
    a {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0, 14px);
    }

    hr {
        height: 1px;
        margin: 30px 0;
        border: 0;
        opacity: 0.7;
        background-color: #D1D5DC;
    }

    h1 {
        font-family: "Ubuntu", sans-serif;
        font-size: 34px;
        line-height: 41px;
        text-align: left;
        color: #454545;
    }

    h4 {
        font-family: "Ubuntu", sans-serif;
        font-size: 16px;
        line-height: 22px;
        text-align: left;
        color: #454545;
        margin: 14px 10px;
    }

    h6 {
        font-family: "Ubuntu", sans-serif;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: #454545;
        margin: 0;
    }

    p {
        font-size: 13px;
        line-height: 1.6;
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-margin-top {
        margin-top: 0 !important;
    }

    .mt-a {
        margin-top: auto !important;
    }

    .mt-0 {
        margin-top: 0 !important;
    }

    .mt-5 {
        margin-top: 5px !important;
    }

    .mt-10 {
        margin-top: 10px !important;
    }

    .mt-15 {
        margin-top: 15px !important;
    }

    .mt-20 {
        margin-top: 20px !important;
    }

    .mt-25 {
        margin-top: 25px !important;
    }

    .mt-30 {
        margin-top: 30px !important;
    }

    .mr-a {
        margin-right: auto !important;
    }

    .mr-0 {
        margin-right: 0 !important;
    }

    .mr-5 {
        margin-right: 5px !important;
    }

    .mr-10 {
        margin-right: 10px !important;
    }

    .mr-15 {
        margin-right: 15px !important;
    }

    .mr-20 {
        margin-right: 20px !important;
    }

    .mr-25 {
        margin-right: 25px !important;
    }

    .mr-30 {
        margin-right: 30px !important;
    }

    .mb-a {
        margin-bottom: auto !important;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .mb-5 {
        margin-bottom: 5px !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }

    .mb-15 {
        margin-bottom: 15px !important;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-25 {
        margin-bottom: 25px !important;
    }

    .mb-30 {
        margin-bottom: 30px !important;
    }

    .ml-a {
        margin-left: auto !important;
    }

    .ml-0 {
        margin-left: 0 !important;
    }

    .ml-5 {
        margin-left: 5px !important;
    }

    .ml-10 {
        margin-left: 10px !important;
    }

    .ml-15 {
        margin-left: 15px !important;
    }

    .ml-20 {
        margin-left: 20px !important;
    }

    .ml-25 {
        margin-left: 25px !important;
    }

    .ml-30 {
        margin-left: 30px !important;
    }

    .pt-0 {
        padding-top: 0 !important;
    }

    .pt-5 {
        padding-top: 5px !important;
    }

    .pt-10 {
        padding-top: 10px !important;
    }

    .pt-15 {
        padding-top: 15px !important;
    }

    .pt-20 {
        padding-top: 20px !important;
    }

    .pt-25 {
        padding-top: 25px !important;
    }

    .pt-30 {
        padding-top: 30px !important;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .pr-5 {
        padding-right: 5px !important;
    }

    .pr-10 {
        padding-right: 10px !important;
    }

    .pr-15 {
        padding-right: 15px !important;
    }

    .pr-20 {
        padding-right: 20px !important;
    }

    .pr-25 {
        padding-right: 25px !important;
    }

    .pr-30 {
        padding-right: 30px !important;
    }

    .pb-0 {
        padding-bottom: 0 !important;
    }

    .pb-5 {
        padding-bottom: 5px !important;
    }

    .pb-10 {
        padding-bottom: 10px !important;
    }

    .pb-15 {
        padding-bottom: 15px !important;
    }

    .pb-20 {
        padding-bottom: 20px !important;
    }

    .pb-25 {
        padding-bottom: 25px !important;
    }

    .pb-30 {
        padding-bottom: 30px !important;
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .pl-5 {
        padding-left: 5px !important;
    }

    .pl-10 {
        padding-left: 10px !important;
    }

    .pl-15 {
        padding-left: 15px !important;
    }

    .pl-20 {
        padding-left: 20px !important;
    }

    .pl-25 {
        padding-left: 25px !important;
    }

    .pl-30 {
        padding-left: 30px !important;
    }

    .no-margin-bottom {
        margin-bottom: 0 !important;
    }

    .no-border {
        border: 0 !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .h-100 {
        height: 100% !important;
    }

    .h-a {
        height: auto !important;
    }

    .w-100 {
        width: 100% !important;
    }

    .w-a {
        width: auto !important;
    }

    ::-webkit-scrollbar {
        display: none;
        width: 0;
        background-color: transparent;
    }

    ::-webkit-scrollbar-track {
        display: none;
        background-color: transparent;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        display: none;
        background-color: transparent;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    #root {
        overflow: hidden;
        position: relative;
        -webkit-overflow-scrolling: touch;

        > div:first-child {
            &:before {
                content: "";
                position: absolute;
                top: 2.5%;
                right: 0;
                bottom: 2.5%;
                left: 0;
                width: 100%;
                border-radius: 18px;
                background-color: #fafafa;
                transition: all 400ms ease-in-out;
                transform: scale(1) translate3d(0, 0, 0);
                will-change: transform;
            }
        }
    }

    .navigation-closed {}

    .navigation-opened {
        #root {
            > div:first-child {
                > div:nth-child(2) {
                    overflow: hidden;
                    transform: scale(0.86) translate3d(68%, 0, 0);
                    border-radius: 18px;
                }

                &:before {
                    transform: scale(0.86) translate3d(64%, 0, 0);
                }
            }
        }
    }

    .loading-screen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        background-color: #FBFBFF;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h4 {
            color: #454545;
            font-weight: 300;
            font-size: 14px;
            margin: 0;
            letter-spacing: 5px;
            text-transform: uppercase;
        }

        video {
            width: 180px;
            height: auto;
            border-radius: 100%;
        }
    }

    .splash-screen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #111;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        z-index: 99;
        background-color: #fff;
        padding: 30px;
        background-size: cover;
        background-position: center;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 4;
            background-color: rgb(69 69 69 / 4%);
            backdrop-filter: saturate(500%) blur(10px);
            text-rendering: geometricPrecision;
        }

        h1 {
            position: relative;
            z-index: 5;
            line-height: 1;
            font-size: 32px;
            text-align: left;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #ffffff;
            margin: 0 0 5px;
            padding: 0 18px;
            text-shadow: 0px 2px rgb(17 17 17 / 30%)
        }

        p {
            position: relative;
            z-index: 5;
            color: #ffffff;
            letter-spacing: 1px;
            margin: 0 0 20px;
            font-size: 12px;
            text-transform: uppercase;
            text-shadow: 0px 1px rgb(17 17 17 / 30%)
        }

        .spinner {
            position: relative;
            z-index: 5;
        }
    }

    .custom-checkbox input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    .custom-checkbox label {
        position: relative;
        cursor: pointer;
        margin: 0 12px;
    }

    .custom-checkbox label:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #454545;
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 100%;
    }

    .custom-checkbox input:checked + label:before {
        border-color: #65c18c;
    }

    .custom-checkbox input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 5px;
        width: 12px;
        height: 12px;
        background: #65c18c;
        border-radius: 100%;
    }
`;

export default GlobalStyle;
