import styled from "styled-components";

const StyledDialog = styled.div`
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 14px;
  padding-top: calc(14px + var(--safe-area-inset-top));
  padding-bottom: calc(14px + var(--safe-area-inset-bottom));
  background-color: rgb(17 17 17 / 20%);
  backdrop-filter: saturate(180%) blur(2px);
  overscroll-behavior: contain;

  .dialog-animation {
    display: flex;
  }

  .dialog-card {
    width: 100%;
    height: 100%;
    padding: 32px 24px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(173, 179, 191, 0.3);
    overscroll-behavior: contain;

    h2 {
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      color: #404852;
      text-align: center;
    }

    p {
      font-family: "Nanum Gothic", sans-serif;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #adb3bf;
    }
  }
`;

export default StyledDialog;
