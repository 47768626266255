import React from "react";
import { useSelector } from "react-redux";
import StyledContent from "./style";

const Content = (props) => {
  const { customInsetTop, showFooter } = useSelector(
    (state) => state.ui.config
  );

  return (
    <StyledContent
      {...props}
      customInsetTop={customInsetTop}
      className={props?.className ? props.className : ""}
      showFooter={showFooter.toString()}
    >
      {props.children}
    </StyledContent>
  );
};

export default Content;
