import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { setCredentials, selectCurrentUser } from "../../Slices/authSlice";
import { selectPlatform, setPlatform } from "../../Slices/platformSlice";
import { setProduct } from "../../Slices/productSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import {
  useLazyGetProfileQuery,
  useUpdateProfileMutation
} from "../../Slices/apiProfileSlice";
import {
  useLazyAppleAuthQuery,
  useLazyGoogleAuthQuery
} from "../../Slices/apiAuthSlice";
import {
  useSendAppleIAPClientConfirmationMutation,
  useSendAndroidIAPClientConfirmationMutation
} from "../../Slices/apiPaymentsSlice";
import { PostMessage } from "../../utils";

const RNEVENTS = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const platform = useSelector(selectPlatform);

  const [getProfile] = useLazyGetProfileQuery();

  const [sendUserActivity] = useSendUserActivityMutation();

  const [updateProfile] = useUpdateProfileMutation();

  const [sendAppleIAPClientConfirmation] =
    useSendAppleIAPClientConfirmationMutation();

  const [sendAndroidIAPClientConfirmation] =
    useSendAndroidIAPClientConfirmationMutation();

  const [
    appleAuth,
    {
      data: appleAuthResponse,
      isLoading: appleAuthIsLoading,
      isSuccess: appleAuthIsSuccess,
      isError: appleAuthIsError,
      error: appleAuthError
    }
  ] = useLazyAppleAuthQuery();

  const [
    googleAuth,
    {
      data: googleAuthResponse,
      isLoading: googleAuthIsLoading,
      isSuccess: googleAuthIsSuccess,
      isError: googleAuthIsError,
      error: googleAuthError
    }
  ] = useLazyGoogleAuthQuery();

  const updateFCMToken = ({ platform, token }) => {
    // user?.id &&
    platform === "ios" &&
      updateProfile({
        id: user?.id,
        body: {
          iosFcmToken: token
        }
      });

    // user?.id &&
    platform === "android" &&
      updateProfile({
        id: user?.id,
        body: {
          androidFcmToken: token
        }
      });
  };

  useEffect(() => {
    window.GlobalEventEmitter.on("RN_FCM_TOKEN", (e) => {
      updateFCMToken(e);
      sendUserActivity({
        type: "update_user_fcm_token",
        data: e
      });
    });

    window.GlobalEventEmitter.on("RN_FCM_MESSAGE", ({ data } = {}) => {
      if (data.purchaseConfirmed === "true" && data.eventType) getProfile();
      if (data.eventType === "SUGGESTED_RECIPE" && data.recipeId)
        navigate(`/recipes/${data.recipeId}`);
    });

    window.GlobalEventEmitter.on(
      "RN_APPLE_AUTH",
      ({ authorizationCode, lastName, firstName } = {}) => {
        appleAuth({
          code: authorizationCode,
          lastName: lastName ? lastName : "",
          firstName: firstName ? firstName : ""
        });
        PostMessage({
          type: "sendFirebaseAnalyticsEvent",
          value: {
            name: "sign_up"
          }
        });
        sendUserActivity({
          type: "apple_auth"
        });
      }
    );

    window.GlobalEventEmitter.on(
      "RN_GOOGLE_AUTH",
      ({ serverAuthCode, lastName, firstName } = {}) => {
        googleAuth({
          code: serverAuthCode,
          lastName: lastName ? lastName : "",
          firstName: firstName ? firstName : ""
        });
        PostMessage({
          type: "sendFirebaseAnalyticsEvent",
          value: {
            name: "sign_up"
          }
        });
        sendUserActivity({
          type: "google_auth"
        });
      }
    );

    window.GlobalEventEmitter.on("RN_APPLE_IAP_CLIENT_CONFIRMATION", (data) => {
      if (data) {
        sendAppleIAPClientConfirmation(data);
        sendUserActivity({
          type: "apple_iap_client_confirmation"
        });
        window.GlobalEventEmitter.off("RN_APPLE_IAP_CLIENT_CONFIRMATION");
      }
    });

    window.GlobalEventEmitter.on(
      "RN_ANDROID_IAP_CLIENT_CONFIRMATION",
      (data) => {
        if (data) {
          sendAndroidIAPClientConfirmation(data);
          sendUserActivity({
            type: "android_iap_client_confirmation"
          });
          window.GlobalEventEmitter.off("RN_ANDROID_IAP_CLIENT_CONFIRMATION");
        }
      }
    );

    window.GlobalEventEmitter.on("RN_SET_PLATFORM", (data) => {
      if (data) {
        dispatch(setPlatform(data));
        sendUserActivity({
          type: "set_platform_info"
        });
      }
    });

    window.GlobalEventEmitter.on("RN_SET_PRODUCT", (data) => {
      if (data) {
        dispatch(setProduct(data));
        sendUserActivity({
          type: "set_product_info"
        });
      }
    });

    window.GlobalEventEmitter.on("RN_PARSE_LINKING_URL", (url) => {
      const urlInstance = new URL(url);
      navigate(urlInstance.pathname);
      sendUserActivity({
        type: "parse_linking_url",
        data: {
          url: url
        }
      });
    });

    window.GlobalEventEmitter.on(
      "RN_PERMISSIONS_STATUS",
      ({ platform, permissions }) => {
        updateProfile({
          id: user?.id,
          body: {
            [platform === "ios"
              ? "iosPermissionsStatus"
              : "androidPermissionsStatus"]: permissions
          }
        });

        sendUserActivity({
          type: "updated_user_permissions_status"
        });
      }
    );

    window.GlobalEventEmitter.on("RN_SEND_USER_ACTIVITY", (data) => {
      sendUserActivity(data);
    });

    return () => {
      window.GlobalEventEmitter.off("RN_FCM_TOKEN");
      window.GlobalEventEmitter.off("RN_FCM_MESSAGE");
      window.GlobalEventEmitter.off("RN_APPLE_AUTH");
      window.GlobalEventEmitter.off("RN_GOOGLE_AUTH");
      window.GlobalEventEmitter.off("RN_SET_PLATFORM");
      window.GlobalEventEmitter.off("RN_SET_PRODUCT");
      window.GlobalEventEmitter.off("RN_PARSE_LINKING_URL");
      window.GlobalEventEmitter.off("RN_PERMISSIONS_STATUS");
      window.GlobalEventEmitter.off("RN_SEND_USER_ACTIVITY");
    };
  }, []);

  useEffect(() => {
    ((appleAuthIsError && appleAuthError) ||
      (googleAuthIsError && googleAuthError)) &&
      NiceModal.show("info-modal", {
        heading: "Oops!",
        description:
          "Something went wrong. We are not sure yet and will examine this case. Try again.",
        hideSubmit: true,
        dismissLabel: "OK"
      });
  }, [appleAuthIsError, appleAuthError, googleAuthIsError, googleAuthError]);

  useEffect(() => {
    if (appleAuthIsSuccess || googleAuthIsSuccess) {
      dispatch(setCredentials(appleAuthResponse || googleAuthResponse));
      navigate("/");
    }
  }, [
    appleAuthIsSuccess,
    appleAuthResponse,
    googleAuthIsSuccess,
    googleAuthResponse
  ]);

  useEffect(() => {
    if (platform && platform?.OS) {
      sendUserActivity({
        type: "app_opened"
      });
    }
  }, [platform]);

  return null;
};

export default RNEVENTS;
