import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHasRoles } from "react-simple-rbac";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import Filter from "../Modals/Filter";
import StyledHeader from "./style";
import { toggleNavigation } from "../../Slices/uiSlice";
import { StyledButtonIcon } from "../../Style";
import { ReactComponent as BarsIcon } from "../../Assets/bars.svg";
import { ReactComponent as SearchIcon } from "../../Assets/magnifying-glass-solid.svg";
import { ReactComponent as ChevronLeftIcon } from "../../Assets/chevron-left.svg";
import { ReactComponent as EllipsisIcon } from "../../Assets/ellipsis-solid.svg";
import { HapticFeedback, PostMessage } from "../../utils";
import { selectCurrentUser } from "../../Slices/authSlice";

const Header = (props) => {
  const isProUser = useHasRoles(["Pro User"]);

  const headerRef = useRef(null);

  const user = useSelector(selectCurrentUser);

  const { id: recipeId } = useParams();

  const dispatch = useDispatch();

  const {
    showHeader,
    customInsetTop,
    mainActionType,
    headerType,
    header,
    secondaryActionType
  } = useSelector((state) => state.ui.config);

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (headerRef?.current?.clientHeight) {
      PostMessage({
        type: "setAppHeaderHeight",
        value: headerRef.current.clientHeight
      });
    }
  });

  return showHeader ? (
    <StyledHeader {...props} customInsetTop={customInsetTop} ref={headerRef}>
      {mainActionType === "navigation" && (
        <StyledButtonIcon
          onClick={() => {
            HapticFeedback();
            dispatch(
              toggleNavigation(
                !document.getElementsByClassName("navigation-opened")[0]
              )
            );
          }}
        >
          <BarsIcon />
        </StyledButtonIcon>
      )}
      {mainActionType === "back" && (
        <StyledButtonIcon
          onClick={() => {
            HapticFeedback();
            PostMessage({
              type: "hideIngredients",
              value: null
            });
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </StyledButtonIcon>
      )}
      {headerType === "user" && (
        <h1
          style={{
            flex: 1,
            display: "flex",
            paddingLeft: "5px"
          }}
        >
          <Link
            to={"/"}
            style={{
              paddingRight:
                (!location.pathname.includes("home") && !props.authHeader) ||
                (location.pathname.includes("forgot-password") &&
                  props.authHeader)
                  ? "40px"
                  : ""
            }}
          >
            👋 {user ? user.firstName : "Awesome"}
            <br />
            <small>What do you want to cook?</small>
          </Link>
        </h1>
      )}
      {headerType === "title" && (
        <h1
          style={
            !secondaryActionType || !isProUser || !user
              ? {
                  color: "#454545",
                  textAlign: "center",
                  width: "100%",
                  marginRight: "38px"
                }
              : {
                  color: "#454545",
                  textAlign: "center"
                }
          }
        >
          {header}
        </h1>
      )}
      {user && secondaryActionType === "filter" && (
        <StyledButtonIcon
          style={{ width: "40px" }}
          onClick={() => {
            HapticFeedback();
            NiceModal.show("filter-modal", { navigate });
          }}
        >
          <SearchIcon />
        </StyledButtonIcon>
      )}
      {user && secondaryActionType === "recipeOptions" && (
        <StyledButtonIcon
          style={{ width: "40px" }}
          onClick={() => {
            HapticFeedback();
            NiceModal.show("recipe-options-modal", {
              recipeId
            });
          }}
        >
          <EllipsisIcon />
        </StyledButtonIcon>
      )}
    </StyledHeader>
  ) : null;
};

export default Header;
