import styled, { css } from "styled-components";

const StyledHeader = styled.div`
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 14px;
  padding-top: calc(23px + var(--safe-area-inset-top));
  min-height: 86px;
  background-color: #ffffff;

  ${({ customInsetTop }) =>
    customInsetTop &&
    css`
      padding-top: calc(23px + ${customInsetTop}px);
    `}

  /* &:after {
    content: "";
    height: 1px;
    position: absolute;
    z-index: 3;
    left: 3.5%;
    right: 3.5%;
    bottom: 0;
    background-color: #dbdbe7;
  } */

  h1 {
    text-transform: capitalize;
    line-height: 1;
    font-size: 16px;
    text-align: left;
    font-weight: bolder;
    letter-spacing: 2px;
    color: #65c18c;
    margin: 0;
    /* flex: 1;
    display: flex; */

    a {
      color: inherit;
      text-decoration: none;

      small {
        font-size: 13px;
        color: #ababab;
        text-transform: none;
        font-family: "Nanum Gothic", sans-serif;
        font-weight: 400;
        margin-top: 10px;
        display: flex;
      }
    }
  }

  button {
    width: 40px;
    margin-right: 10px;
    line-height: 1;
    box-shadow: 0px 2px 10px rgb(173 179 191 / 15%);
    border-radius: 12px;

    &:last-child {
      float: right;
    }
  }

  &.recipe-details {
    line-height: 86px;

    /* &:before {
      content: "";
      position: absolute;
      left: 3.5%;
      right: 3.5%;
      bottom: 0;
      height: 1px;
      background-color: #dbdbe7;
    } */

    h1 {
      font-size: 14px;
    }

    button {
      &:first-child {
        margin-right: 10px;
        line-height: 1;
      }

      &:last-child {
        padding: 3px 4px;
        width: 30px;

        svg {
          height: 30px;
        }
      }
    }
  }

  &.static-header {
    &:after {
      left: 0;
      right: 0;
    }

    > .columns {
      width: 100%;

      > .column {
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
          margin: 0;
          margin-right: auto;

          > a {
            display: block;
          }
        }
      }
    }
  }
`;

export default StyledHeader;
