import styled from "styled-components";

const StyledNavigation = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  width: 250px;
  height: 100%;
  transform: translateY(-50%);

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin: 0;
    padding: 30px 0;
    list-style: none;
    transform: scale(0.86);

    li {
      &:last-child {
        width: 100%;

        button {
          text-transform: uppercase;
          line-height: 42px;
          min-height: 42px;
        }
      }

      h1 {
        line-height: 1;
        font-size: 22px;
        text-align: left;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #454545;
        margin: 15px 0 0;
        padding: 0 18px;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: "Nanum Gothic", sans-serif;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 50px;
        text-align: left;
        color: #454545;
        text-decoration: none;
        padding: 0 18px;

        svg {
          width: 16px;
          fill: #454545;
          margin: 0 14px 0 0;
        }
      }
    }
  }
`;

export default StyledNavigation;
