import React from "react";
import { useField } from "formik";
import { StyledSpinner } from "../../../Style";

const Field = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {props.type === "select" ? (
        <select
          {...field}
          {...props}
          className={
            meta.touched && meta.error
              ? meta.error
                ? "invalid-field"
                : "valid-field"
              : ""
          }
        >
          {props?.values?.map(({ value, label }, key) => (
            <option value={value} label={label} key={key}>
              {label}
            </option>
          ))}
        </select>
      ) : (
        <input
          {...field}
          {...props}
          className={
            meta.touched && meta.error
              ? meta.error
                ? "invalid-field"
                : "valid-field"
              : ""
          }
        />
      )}
      {meta.touched && meta.error && (
        <span
          style={{
            position: "absolute",
            color: "#FF617C",
            fontSize: "10px",
            left: "0",
            bottom: "-18px"
          }}
        >
          {meta.error}
        </span>
      )}
      {props?.loading && (
        <StyledSpinner
          className="spinner"
          style={{ borderLeftColor: "#65c18c" }}
        />
      )}
    </>
  );
};

export { Field };
