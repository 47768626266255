import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRBACContext } from "react-simple-rbac";
import { ErrorBoundary } from "react-error-boundary";
import NiceModal from "@ebay/nice-modal-react";
import {
  Dialog,
  AuthRouteLayout,
  PrivateRouteLayout,
  RNEVENTS,
  Paywall
} from "./Components";
// import PrivateRoutes from "./PrivateRoutes";
import Welcome from "./Modules/Welcome";
import LogIn from "./Modules/LogIn";
import SignUp from "./Modules/SignUp";
import ForgotPassword from "./Modules/ForgotPassword";
import ResetPassword from "./Modules/ResetPassword";
import StyledConfirmEmailAddress from "./Modules/ConfirmEmailAddress";
import RecipesStatic from "./Modules/Recipes/Static";
import AogRoutes from "./Modules/AOG";
import { useLazyGetProfileQuery } from "./Slices/apiProfileSlice";
import { selectSkippedWelcomeScreen } from "./Slices/generalSlice";
import { selectCurrentUser, selectCurrentUserToken } from "./Slices/authSlice";
const Recipes = React.lazy(() => import("./Modules/Recipes"));
const Favorites = React.lazy(() => import("./Modules/Favorites"));
const Recipe = React.lazy(() => import("./Modules/Recipe"));
const Steps = React.lazy(() => import("./Modules/Steps"));
const Planner = React.lazy(() => import("./Modules/Planner"));
const GroceryList = React.lazy(() => import("./Modules/GroceryList"));
const Profile = React.lazy(() => import("./Modules/Profile"));

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const App = () => {
  const [getProfile] = useLazyGetProfileQuery();

  const user = useSelector(selectCurrentUser);

  const userToken = useSelector(selectCurrentUserToken);

  const skippedWelcomeScreen = useSelector(selectSkippedWelcomeScreen);

  const { addRoles } = useRBACContext();

  useEffect(() => {
    userToken && getProfile();
  }, [userToken]);

  useEffect(() => {
    user?.role?.name && addRoles([user?.role?.name]);
  }, [user]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <NiceModal.Provider>
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
          <RNEVENTS />
          <Routes>
            <Route path="/">
              <Route
                exact
                path="welcome"
                element={
                  <AuthRouteLayout>
                    <Welcome />
                  </AuthRouteLayout>
                }
              />
              <Route
                exact
                path="login"
                element={
                  <AuthRouteLayout>
                    <LogIn />
                  </AuthRouteLayout>
                }
              />
              <Route
                exact
                path="signup"
                element={
                  <AuthRouteLayout>
                    <SignUp />
                  </AuthRouteLayout>
                }
              />
              <Route
                exact
                path="forgot-password"
                element={
                  <AuthRouteLayout>
                    <ForgotPassword />
                  </AuthRouteLayout>
                }
              />
              <Route
                exact
                path="reset-password"
                element={
                  <AuthRouteLayout>
                    <ResetPassword />
                  </AuthRouteLayout>
                }
              />
              <Route
                exact
                path="confirm-email-address"
                element={
                  <AuthRouteLayout>
                    <StyledConfirmEmailAddress />
                  </AuthRouteLayout>
                }
              />
              <Route exact path="catalog" element={<RecipesStatic />} />
              <Route path="aog" element={<AogRoutes />} />
              <Route
                exact
                path="favorites"
                element={
                  <Suspense fallback={<>...</>}>
                    <PrivateRouteLayout>
                      <Favorites />
                    </PrivateRouteLayout>
                  </Suspense>
                }
              />
              <Route
                exact
                path="recipes/:id"
                element={
                  <Suspense fallback={<>...</>}>
                    <PrivateRouteLayout>
                      <Recipe />
                    </PrivateRouteLayout>
                  </Suspense>
                }
              />
              <Route
                exact
                path="recipes/:id/steps"
                element={
                  <Suspense fallback={<>...</>}>
                    <PrivateRouteLayout>
                      <Steps />
                    </PrivateRouteLayout>
                  </Suspense>
                }
              />
              <Route
                exact
                path="planner"
                element={
                  <Suspense fallback={<>...</>}>
                    <PrivateRouteLayout>
                      <Planner />
                    </PrivateRouteLayout>
                  </Suspense>
                }
              />
              <Route
                exact
                path="shopping-lists"
                element={
                  <Suspense fallback={<>...</>}>
                    <PrivateRouteLayout>
                      <GroceryList />
                    </PrivateRouteLayout>
                  </Suspense>
                }
              />
              <Route
                exact
                path="profile"
                element={
                  userToken || user ? (
                    <Suspense fallback={<>...</>}>
                      <PrivateRouteLayout>
                        <Profile />
                      </PrivateRouteLayout>
                    </Suspense>
                  ) : (
                    <Navigate to="/welcome" />
                  )
                }
              />
              <Route
                exact
                path="/"
                element={
                  userToken || user || skippedWelcomeScreen ? (
                    <Suspense fallback={<>...</>}>
                      <PrivateRouteLayout>
                        <Recipes />
                      </PrivateRouteLayout>
                    </Suspense>
                  ) : (
                    <Navigate to="/welcome" />
                  )
                }
              />
            </Route>
          </Routes>
          <Paywall />
        </BrowserRouter>
      </NiceModal.Provider>
    </ErrorBoundary>
  );
};

export default App;
