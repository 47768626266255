import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { ButtonBase } from "@mui/material";
import BaseModal from "./BaseModal";
import { Field, Incrementor } from "../";
import {
  StyledButton,
  StyledButtonDark,
  StyledButtonNegativeSmall,
  StyledButtonIcon,
  StyledForm,
  StyledFormGroup,
  StyledFakeField
} from "../../Style";
import { HapticFeedback, PostMessage } from "../../utils";
import { selectFilter, setFilter } from "../../Slices/filterSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import { setRecipes } from "../../Slices/recipeSlice";
import { useCreateUserFilterMutation } from "../../Slices/apiUserFiltersSlice";
import {
  useLazyGetRecipesQuery,
  resetApiRecipesState
} from "../../Slices/apiRecipesSlice";
import {
  useLazyGetIngredientsQuery,
  resetApiState
} from "../../Slices/apiIngredientsSlice";
import { useGetDietsQuery } from "../../Slices/apiDietsSlice";
import { useGetDishTypesQuery } from "../../Slices/apiDishTypesSlice";
import { useGetCoursesQuery } from "../../Slices/apiCoursesSlice";
import { useGetCuisinesQuery } from "../../Slices/apiCuisinesSlice";
import { ReactComponent as ChevronLeftIcon } from "../../Assets/chevron-left.svg";
import Recipe from "../../Modules/Recipes/recipe";

const ListItem = styled("li")(() => ({
  marginRight: "10px",
  marginBottom: "10px",
  display: "inline-flex"
}));

const time = [10, 20, 30, 40, 60];

const calories = [200, 400, 600, 800, 1200];

const Filter = NiceModal.create(({ navigate }) => {
  const filterRef = useRef();

  const debounceWaitRef = useRef();

  const modal = useModal();

  const filter = useSelector(selectFilter);

  const dispatch = useDispatch();

  const [sendUserActivity] = useSendUserActivityMutation();

  const [
    createUserFilter,
    {
      data: createUserFilterResult,
      isLoading: createUserFilterIsLoading,
      isFetching: createUserFilterIsFetching,
      isError: createUserFilterIsError,
      error: createUserFilterError
    }
  ] = useCreateUserFilterMutation();

  const [
    getRecipes,
    {
      data: getRecipesResult,
      isLoading: getRecipesIsLoading,
      isFetching: getRecipesIsFetching,
      isError: getRecipesIsError,
      error: getRecipesError
    }
  ] = useLazyGetRecipesQuery();

  const [
    getIngredients,
    {
      data: getIngredientsResult,
      isLoading: getIngredientsIsLoading,
      isFetching: getIngredientsIsFetching,
      isError: getIngredientsIsError,
      error: getIngredientsError
    }
  ] = useLazyGetIngredientsQuery();

  const {
    data: getDietsResult,
    isLoading: getDietsIsLoading,
    isFetching: getDietsIsFetching,
    isError: getDietsIsError,
    error: getDietsError
  } = useGetDietsQuery();

  const {
    data: getDishTypesResult,
    isLoading: getDishTypesIsLoading,
    isFetching: getDishTypesIsFetching,
    isError: getDishTypesIsError,
    error: getDishTypesError
  } = useGetDishTypesQuery();

  const {
    data: getCoursesResult,
    isLoading: getCoursesIsLoading,
    isFetching: getCoursesIsFetching,
    isError: getCoursesIsError,
    error: getCoursesError
  } = useGetCoursesQuery();

  const {
    data: getCuisinesResult,
    isLoading: getCuisinesIsLoading,
    isFetching: getCuisinesIsFetching,
    isError: getCuisinesIsError,
    error: getCuisinesError
  } = useGetCuisinesQuery();

  const showSaveFilterModal = async () => {
    const result = await NiceModal.show("save-filter");

    result && createUserFilter({ ...filterRef?.current?.values, ...result });
  };

  useEffect(() => {
    PostMessage({
      type: "requestAppTrackingPermissions"
    });
  }, []);

  return (
    <BaseModal
      fullHeight
      hideActions
      hideDismiss
      hideSubmit
      onDismiss={() => {
        HapticFeedback();
        modal.remove();
      }}
      visible={modal.visible}
      loading={
        (getDietsIsLoading ||
          getDishTypesIsLoading ||
          getCoursesIsLoading ||
          getCuisinesIsLoading) &&
        "modal"
      }
    >
      <Formik
        innerRef={filterRef}
        onSubmit={async (values) => {
          if (!values.ingredientsView) {
            dispatch(setRecipes([]));
            dispatch(
              setFilter({
                start: 0,
                limit: 15,
                title: values.title,
                servings: values.servings,
                time: values.time,
                calories: values.calories,
                diet: values.diet,
                dishType: values.dishType,
                course: values.course,
                cuisine: values.cuisine,
                includeIngredients: values.includeIngredients,
                excludeIngredients: values.excludeIngredients
              })
            );
            sendUserActivity({
              type: "search",
              data: values
            });
            modal.remove();
          } else {
            return false;
          }
        }}
        initialValues={{
          ingredientsView: false,
          includeIngredientsDummy: "",
          includeIngredients: filter.includeIngredients,
          excludeIngredientsDummy: "",
          excludeIngredients: filter.excludeIngredients,
          title: filter.title,
          servings: filter.servings,
          time: filter.time,
          calories: filter.calories,
          diet: filter.diet,
          dishType: filter.dishType,
          course: filter.course,
          cuisine: filter.cuisine
        }}
      >
        {({ values, setFieldValue, setFieldTouched, touched, resetForm }) => {
          return (
            <FormikForm
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {!values.ingredientsView && (
                <div
                  className="mb-30"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <StyledButtonIcon
                    style={{
                      boxShadow: "0px 2px 10px rgb(173 179 191 / 15%)",
                      borderRadius: "12px"
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      HapticFeedback();
                      modal.remove();
                    }}
                  >
                    <ChevronLeftIcon />
                  </StyledButtonIcon>
                  <h2 className="mt-0 mb-0 mr-auto ml-20">Discover</h2>
                  {/* <StyledButtonNegativeSmall
                    className="mb-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      HapticFeedback();
                      showSaveFilterModal();
                    }}
                    loading={createUserFilterIsLoading}
                    disabled={createUserFilterResult}
                  >
                    {createUserFilterResult ? "Saved" : "Save Filter"}
                  </StyledButtonNegativeSmall> */}
                </div>
              )}
              <div
                className="mb-30"
                style={{
                  flexGrow: 1,
                  overflow: "auto",
                  minHeight: 0
                }}
              >
                <StyledForm
                  style={{
                    height: "100%"
                  }}
                >
                  <div
                    style={
                      values.ingredientsView === "recipeByName"
                        ? {
                            height: "100%",
                            display: "flex",
                            flexDirection: "column"
                          }
                        : {}
                    }
                  >
                    {(values.ingredientsView === "recipeByName" ||
                      !values.ingredientsView) && (
                      <StyledFormGroup outlined>
                        <Field
                          name="title"
                          type="text"
                          placeholder="Search Recipe by name"
                          inputMode="search"
                          onClick={(e) => {
                            HapticFeedback();
                            setFieldValue("ingredientsView", "recipeByName");
                            dispatch(resetApiRecipesState());
                          }}
                          onChange={(e) => {
                            HapticFeedback();
                            clearTimeout(debounceWaitRef?.current);
                            setFieldValue("title", e?.target?.value);
                            if (e?.target?.value || e?.target?.value !== "") {
                              debounceWaitRef.current = setTimeout(() => {
                                getRecipes({
                                  title: e?.target?.value,
                                  start: 0,
                                  limit: 20
                                });
                              }, 500);
                            }
                          }}
                          onKeyPress={(e) => {
                            if (
                              e.key === "Enter" ||
                              e.keyCode === 13 ||
                              e.which === 13
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                              HapticFeedback();
                              e?.target?.value &&
                                setFieldValue("title", e?.target?.value);
                            }
                          }}
                          autoFocus={values.ingredientsView}
                          loading={getRecipesIsFetching}
                        />
                      </StyledFormGroup>
                    )}
                    {values.ingredientsView === "recipeByName" &&
                      getRecipesResult &&
                      !!getRecipesResult.length && (
                        <div
                          style={{
                            flexGrow: 1,
                            overflow: "auto",
                            minHeight: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                          }}
                        >
                          {getRecipesResult.map(
                            (
                              {
                                title,
                                _id,
                                cover: {
                                  formats: {
                                    large = null,
                                    medium = null,
                                    small = null,
                                    thumbnail = null
                                  } = {}
                                }
                              },
                              key
                            ) => (
                              <ButtonBase
                                style={{
                                  width: "100%",
                                  padding: "15px 0",
                                  display: "flex",
                                  gap: "10px"
                                }}
                                key={key}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  HapticFeedback();
                                  navigate(`/recipes/${_id}`);
                                  modal.remove();
                                  // setFieldValue("includeIngredients", [
                                  //   ...values.includeIngredients,
                                  //   name
                                  // ]);
                                }}
                              >
                                {/* &#8594;{" "} */}
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${
                                    medium?.url ||
                                    small?.url ||
                                    large?.url ||
                                    thumbnail?.url
                                  }`}
                                  alt="Recipe"
                                  draggable={false}
                                  style={{
                                    display: "flex",
                                    width: "55px",
                                    height: "55px",
                                    objectFit: "cover",
                                    borderRadius: "55px"
                                  }}
                                />
                                <h4
                                  style={{
                                    width: "100%",
                                    textTransform: "capitalize",
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    flex: "1"
                                  }}
                                >
                                  {title}
                                </h4>
                              </ButtonBase>
                            )
                          )}
                        </div>
                      )}
                  </div>
                  <div
                    style={
                      values.ingredientsView === "includeIngredients"
                        ? {
                            height: "100%",
                            display: "flex",
                            flexDirection: "column"
                          }
                        : {}
                    }
                  >
                    {values.ingredientsView &&
                    values.ingredientsView === "includeIngredients" &&
                    !!values.includeIngredients.length ? (
                      <>
                        <p style={{ textAlign: "left" }} className="mt-0">
                          Selected Ingredients
                        </p>
                        {values.includeIngredients.map((chip) => (
                          <ListItem key={chip}>
                            <Chip
                              label={chip}
                              onDelete={() =>
                                setFieldValue(
                                  "includeIngredients",
                                  values.includeIngredients.filter(
                                    (ing) => ing !== chip
                                  )
                                )
                              }
                              sx={{
                                bgcolor: "#65c18c",
                                borderColor: "#65c18c",
                                color: "#ffffff",
                                fontSize: "13px",
                                borderRadius: "6px",
                                fontFamily: "Nanum Gothic"
                              }}
                            />
                          </ListItem>
                        ))}
                        <div className="mb-20" />
                      </>
                    ) : null}
                    {!values.ingredientsView &&
                      !!values.includeIngredients.length && (
                        <StyledFakeField
                          outlined
                          onClick={(e) => {
                            HapticFeedback();
                            setFieldValue(
                              "ingredientsView",
                              "includeIngredients"
                            );
                          }}
                        >
                          {values.includeIngredients.map((chip) => (
                            <ListItem key={chip}>
                              <Chip
                                label={chip}
                                onDelete={() =>
                                  setFieldValue(
                                    "includeIngredients",
                                    values.includeIngredients.filter(
                                      (ing) => ing !== chip
                                    )
                                  )
                                }
                                sx={{
                                  bgcolor: "#65c18c",
                                  borderColor: "#65c18c",
                                  color: "#ffffff",
                                  fontSize: "13px",
                                  borderRadius: "6px",
                                  fontFamily: "Nanum Gothic"
                                }}
                              />
                            </ListItem>
                          ))}
                          <br />
                          Tap to change
                        </StyledFakeField>
                      )}
                    {(values.ingredientsView === "includeIngredients" ||
                      (!values.ingredientsView &&
                        !values.includeIngredients.length)) && (
                      <StyledFormGroup outlined>
                        <Field
                          name="includeIngredientsDummy"
                          type="text"
                          placeholder="Include Ingredients"
                          inputMode="search"
                          onClick={(e) => {
                            HapticFeedback();
                            setFieldValue(
                              "ingredientsView",
                              "includeIngredients"
                            );
                            dispatch(resetApiState());
                          }}
                          onChange={(e) => {
                            HapticFeedback();
                            clearTimeout(debounceWaitRef?.current);
                            setFieldValue(
                              "includeIngredientsDummy",
                              e?.target?.value
                            );
                            debounceWaitRef.current = setTimeout(() => {
                              getIngredients({
                                name_contains: e?.target?.value
                                // _limit: 10
                              });
                            }, 250);
                          }}
                          // onBlur={(e) => {
                          //   HapticFeedback();
                          //   e?.target?.value &&
                          //     setFieldValue("includeIngredients", [
                          //       ...values.includeIngredients,
                          //       e?.target?.value
                          //     ]);
                          //   setFieldValue("includeIngredientsDummy", "");
                          // }}
                          onKeyPress={(e) => {
                            if (
                              e.key === "Enter" ||
                              e.keyCode === 13 ||
                              e.which === 13
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                              HapticFeedback();
                              e?.target?.value &&
                                setFieldValue("includeIngredients", [
                                  ...values.includeIngredients,
                                  e?.target?.value
                                ]);
                              setFieldValue("includeIngredientsDummy", "");
                            }
                          }}
                          autoFocus={values.ingredientsView}
                          loading={getIngredientsIsFetching}
                        />
                      </StyledFormGroup>
                    )}
                    {values.ingredientsView === "includeIngredients" &&
                      getIngredientsResult &&
                      !!getIngredientsResult.length && (
                        <div
                          style={{
                            flexGrow: 1,
                            overflow: "auto",
                            minHeight: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                          }}
                        >
                          {getIngredientsResult
                            .filter(
                              ({ name }) =>
                                !values.includeIngredients.includes(name)
                            )
                            .map(({ name }, key) => (
                              <ButtonBase
                                style={{
                                  width: "100%",
                                  padding: "15px 0"
                                }}
                                key={key}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  HapticFeedback();
                                  setFieldValue("includeIngredients", [
                                    ...values.includeIngredients,
                                    name
                                  ]);
                                  setFieldValue("includeIngredientsDummy", "");
                                }}
                              >
                                <h4
                                  style={{
                                    width: "100%",
                                    textTransform: "capitalize",
                                    margin: 0
                                  }}
                                >
                                  &#8594; {name}
                                </h4>
                              </ButtonBase>
                            ))}
                        </div>
                      )}
                  </div>
                  <div
                    style={
                      values.ingredientsView === "excludeIngredients"
                        ? {
                            height: "100%",
                            display: "flex",
                            flexDirection: "column"
                          }
                        : {}
                    }
                  >
                    {values.ingredientsView &&
                    values.ingredientsView === "excludeIngredients" &&
                    !!values.excludeIngredients.length ? (
                      <>
                        <p style={{ textAlign: "left" }} className="mt-0">
                          Selected Ingredients
                        </p>
                        {values.excludeIngredients.map((chip) => (
                          <ListItem key={chip}>
                            <Chip
                              label={chip}
                              onDelete={() =>
                                setFieldValue(
                                  "excludeIngredients",
                                  values.excludeIngredients.filter(
                                    (ing) => ing !== chip
                                  )
                                )
                              }
                              sx={{
                                bgcolor: "#65c18c",
                                borderColor: "#65c18c",
                                color: "#ffffff",
                                fontSize: "13px",
                                borderRadius: "6px",
                                fontFamily: "Nanum Gothic"
                              }}
                            />
                          </ListItem>
                        ))}
                        <div className="mb-20" />
                      </>
                    ) : null}
                    {!values.ingredientsView &&
                      !!values.excludeIngredients.length && (
                        <StyledFakeField
                          outlined
                          onClick={(e) => {
                            HapticFeedback();
                            setFieldValue(
                              "ingredientsView",
                              "excludeIngredients"
                            );
                          }}
                        >
                          {values.excludeIngredients.map((chip) => (
                            <ListItem key={chip}>
                              <Chip
                                label={chip}
                                onDelete={() =>
                                  setFieldValue(
                                    "excludeIngredients",
                                    values.excludeIngredients.filter(
                                      (ing) => ing !== chip
                                    )
                                  )
                                }
                                sx={{
                                  bgcolor: "#65c18c",
                                  borderColor: "#65c18c",
                                  color: "#ffffff",
                                  fontSize: "13px",
                                  borderRadius: "6px",
                                  fontFamily: "Nanum Gothic"
                                }}
                              />
                            </ListItem>
                          ))}
                          <br />
                          Tap to change
                        </StyledFakeField>
                      )}
                    {(values.ingredientsView === "excludeIngredients" ||
                      (!values.ingredientsView &&
                        !values.excludeIngredients.length)) && (
                      <StyledFormGroup outlined>
                        <Field
                          name="excludeIngredientsDummy"
                          type="text"
                          placeholder="Exclude Ingredients"
                          inputMode="search"
                          onClick={(e) => {
                            HapticFeedback();
                            setFieldValue(
                              "ingredientsView",
                              "excludeIngredients"
                            );
                            dispatch(resetApiState());
                          }}
                          onChange={(e) => {
                            HapticFeedback();
                            clearTimeout(debounceWaitRef?.current);
                            setFieldValue(
                              "excludeIngredientsDummy",
                              e?.target?.value
                            );
                            debounceWaitRef.current = setTimeout(() => {
                              getIngredients({
                                name_contains: e?.target?.value
                                // _limit: 10
                              });
                            }, 250);
                          }}
                          // onBlur={(e) => {
                          //   HapticFeedback();
                          //   e?.target?.value &&
                          //     setFieldValue("excludeIngredients", [
                          //       ...values.excludeIngredients,
                          //       e?.target?.value
                          //     ]);
                          //   setFieldValue("excludeIngredientsDummy", "");
                          // }}
                          onKeyPress={(e) => {
                            if (
                              e.key === "Enter" ||
                              e.keyCode === 13 ||
                              e.which === 13
                            ) {
                              e.stopPropagation();
                              e.preventDefault();
                              HapticFeedback();
                              e?.target?.value &&
                                setFieldValue("excludeIngredients", [
                                  ...values.excludeIngredients,
                                  e?.target?.value
                                ]);
                              setFieldValue("excludeIngredientsDummy", "");
                            }
                          }}
                          autoFocus={values.ingredientsView}
                          loading={getIngredientsIsFetching}
                        />
                      </StyledFormGroup>
                    )}
                    {values.ingredientsView === "excludeIngredients" &&
                      getIngredientsResult &&
                      !!getIngredientsResult.length && (
                        <div
                          style={{
                            flexGrow: 1,
                            overflow: "auto",
                            minHeight: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                          }}
                        >
                          {getIngredientsResult
                            .filter(
                              ({ name }) =>
                                !values.excludeIngredients.includes(name)
                            )
                            .map(({ name }, key) => (
                              <ButtonBase
                                style={{
                                  width: "100%",
                                  padding: "15px 0"
                                }}
                                key={key}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  HapticFeedback();
                                  setFieldValue("excludeIngredients", [
                                    ...values.excludeIngredients,
                                    name
                                  ]);
                                  setFieldValue("excludeIngredientsDummy", "");
                                }}
                              >
                                <h4
                                  style={{
                                    width: "100%",
                                    textTransform: "capitalize",
                                    margin: 0
                                  }}
                                >
                                  &#8594; {name}
                                </h4>
                              </ButtonBase>
                            ))}
                        </div>
                      )}
                  </div>
                  {!values.ingredientsView && (
                    <>
                      <h4>Servings</h4>
                      <Incrementor
                        amount={values.servings}
                        changeCallback={(amount) => {
                          setFieldValue("servings", amount);
                          setFieldTouched("servings", true);
                        }}
                      />
                      <h4>Time</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          justifyContent: "start",
                          marginBottom: "20px"
                        }}
                      >
                        {time.map((val, key) => (
                          <Chip
                            key={key}
                            label={`Under ${val} Min`}
                            onClick={() => {
                              HapticFeedback();
                              values.time !== val
                                ? setFieldValue("time", val)
                                : setFieldValue("time", null);
                              setFieldTouched("time", true);
                            }}
                            variant={values.time === val ? "solid" : "outlined"}
                          />
                        ))}
                      </div>
                      <h4>Calories</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          justifyContent: "start",
                          marginBottom: "20px"
                        }}
                      >
                        {calories.map((val, key) => (
                          <Chip
                            key={key}
                            label={`Under ${val}`}
                            onClick={() => {
                              HapticFeedback();
                              values.calories !== val
                                ? setFieldValue("calories", val)
                                : setFieldValue("calories", null);
                              setFieldTouched("calories", true);
                            }}
                            variant={
                              values.calories === val ? "solid" : "outlined"
                            }
                          />
                        ))}
                      </div>
                      <h4>Diet</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          justifyContent: "start",
                          marginBottom: "20px"
                        }}
                      >
                        <FieldArray
                          name="diet"
                          render={(arrayHelpers) => (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                justifyContent: "start"
                              }}
                            >
                              {getDietsResult?.map(({ _id, name }, key) => (
                                <Chip
                                  key={key}
                                  label={name}
                                  onClick={() => {
                                    HapticFeedback();
                                    setFieldTouched("diet", true);
                                    values.diet.includes(_id)
                                      ? arrayHelpers.remove(
                                          values.diet.indexOf(_id)
                                        )
                                      : arrayHelpers.push(_id);
                                  }}
                                  variant={
                                    values.diet.includes(_id)
                                      ? "solid"
                                      : "outlined"
                                  }
                                />
                              ))}
                            </div>
                          )}
                        />
                      </div>
                      <h4>Dish Type</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          justifyContent: "start",
                          marginBottom: "20px"
                        }}
                      >
                        <FieldArray
                          name="dishType"
                          render={(arrayHelpers) => (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                justifyContent: "start"
                              }}
                            >
                              {getDishTypesResult?.map(({ _id, name }, key) => (
                                <Chip
                                  key={key}
                                  label={name}
                                  onClick={() => {
                                    HapticFeedback();
                                    setFieldTouched("dishType", true);
                                    values.dishType.includes(_id)
                                      ? arrayHelpers.remove(
                                          values.dishType.indexOf(_id)
                                        )
                                      : arrayHelpers.push(_id);
                                  }}
                                  variant={
                                    values.dishType.includes(_id)
                                      ? "solid"
                                      : "outlined"
                                  }
                                />
                              ))}
                            </div>
                          )}
                        />
                      </div>
                      <h4>Course</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          justifyContent: "start",
                          marginBottom: "20px"
                        }}
                      >
                        <FieldArray
                          name="course"
                          render={(arrayHelpers) => (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                justifyContent: "start"
                              }}
                            >
                              {getCoursesResult?.map(({ _id, name }, key) => (
                                <Chip
                                  key={key}
                                  label={name}
                                  onClick={() => {
                                    HapticFeedback();
                                    setFieldTouched("course", true);
                                    values.course.includes(_id)
                                      ? arrayHelpers.remove(
                                          values.course.indexOf(_id)
                                        )
                                      : arrayHelpers.push(_id);
                                  }}
                                  variant={
                                    values.course.includes(_id)
                                      ? "solid"
                                      : "outlined"
                                  }
                                />
                              ))}
                            </div>
                          )}
                        />
                      </div>
                      <h4>Cuisines</h4>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                          justifyContent: "start",
                          marginBottom: "20px"
                        }}
                      >
                        <FieldArray
                          name="cuisine"
                          render={(arrayHelpers) => (
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                justifyContent: "start"
                              }}
                            >
                              {getCuisinesResult?.map(({ _id, name }, key) => (
                                <Chip
                                  key={key}
                                  label={name}
                                  onClick={() => {
                                    HapticFeedback();
                                    setFieldTouched("cuisine", true);
                                    values.cuisine.includes(_id)
                                      ? arrayHelpers.remove(
                                          values.cuisine.indexOf(_id)
                                        )
                                      : arrayHelpers.push(_id);
                                  }}
                                  variant={
                                    values.cuisine.includes(_id)
                                      ? "solid"
                                      : "outlined"
                                  }
                                />
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    </>
                  )}
                </StyledForm>
              </div>
              {!values.ingredientsView && (
                <div className="columns is-mobile">
                  {!!Object.keys(touched).length && (
                    <div className="column">
                      <StyledButtonDark
                        className="mb-0"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          HapticFeedback();
                          resetForm();
                        }}
                      >
                        Clear
                      </StyledButtonDark>
                    </div>
                  )}
                  <div className="column">
                    <StyledButton
                      className="mb-0"
                      onClick={() => {
                        HapticFeedback();
                      }}
                    >
                      Filter
                    </StyledButton>
                  </div>
                </div>
              )}
              {values.ingredientsView && (
                <div className="columns is-mobile">
                  <div className="column">
                    <StyledButton
                      onClick={(e) => {
                        e.stopPropagation();
                        HapticFeedback();
                        setFieldValue("ingredientsView", false);
                        setFieldValue("title", "");
                        setFieldValue("includeIngredientsDummy", "");
                        setFieldValue("excludeIngredientsDummy", "");
                      }}
                      className="mb-0"
                    >
                      Done
                    </StyledButton>
                  </div>
                </div>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </BaseModal>
  );
});

export default Filter;
