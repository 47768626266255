import React from "react";
import { Wrapper, Layout, Content } from "../index";

const AuthRouteLayout = ({ children }) => (
  <div style={{ height: "100%" }}>
    <Wrapper>
      <Layout>
        <Content
          className="pt-0 pb-0"
          style={{ paddingLeft: "34px", paddingRight: "34px" }}
        >
          {children}
        </Content>
      </Layout>
    </Wrapper>
  </div>
);

export default AuthRouteLayout;
