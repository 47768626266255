import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiCookingSession = createApi({
  reducerPath: "apiCookingSession",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  keepUnusedDataFor: 600,
  endpoints: (builder) => ({
    getCookingSessions: builder.query({
      query: () => `/cooking-sessions`,
      providesTags: ["CookingSessions"]
    }),
    getCookingSession: builder.query({
      query: (recipeId) => `/cooking-sessions/${recipeId}`,
      providesTags: ["CookingSession"]
    }),
    startCookingSession: builder.mutation({
      query: ({ recipeId }) => ({
        url: `/cooking-sessions`,
        method: "POST",
        body: {
          recipeId: recipeId
        }
      }),
      invalidatesTags: ["CookingSession"]
    }),
    updateCookingSession: builder.mutation({
      query: ({ id, data } = {}) => {
        return {
          url: `/cooking-sessions/${id}`,
          method: "PUT",
          body: data
        };
      },
      invalidatesTags: ["CookingSessions", "CookingSession"],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiCookingSession.util.updateQueryData(
            "getCookingSessions",
            undefined,
            (draft) => {
              return draft.filter((item) => item.id !== id);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          dispatch(apiCookingSession.util.invalidateTags(["CookingSessions"]));
        }
      }
    })
  })
});

export const {
  useGetCookingSessionsQuery,
  useLazyGetCookingSessionsQuery,
  useGetCookingSessionQuery,
  useStartCookingSessionMutation,
  useUpdateCookingSessionMutation
} = apiCookingSession;
