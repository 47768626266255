import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHasRoles } from "react-simple-rbac";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import BaseModal from "./BaseModal";
import { HapticFeedback, PostMessage } from "../../utils";
import { DatePicker } from "..";
import { selectCurrentUser } from "../../Slices/authSlice";
import { selectRecipeById } from "../../Slices/recipeSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import {
  useGetPlannersQuery,
  useAddPlannerMutation,
  useAddRecipeToPlannerMutation
} from "../../Slices/apiPlannerSlice";

const AddRecipeToPlanner = NiceModal.create(
  ({ recipeId, recipeHasGroceriesList }) => {
    const modal = useModal();

    const isProUser = useHasRoles(["Pro User"]);

    const [startDate, setStartDate] = useState(new Date());

    const [sendUserActivity] = useSendUserActivityMutation();

    const { id: userId } = useSelector(selectCurrentUser);

    const recipeById = useSelector((state) =>
      selectRecipeById(state, recipeId)
    );

    const [
      addPlanner,
      {
        isLoading: addPlannerLoading,
        isSuccess: addPlannerSuccess,
        isError: addPlannerError
      }
    ] = useAddPlannerMutation();

    const [
      addRecipeToPlanner,
      {
        isLoading: addRecipeToPlannerLoading,
        isSuccess: addRecipeToPlannerSuccess,
        isError: addRecipeToPlannerError
      }
    ] = useAddRecipeToPlannerMutation();

    useEffect(() => {
      if (
        addPlannerSuccess
        //   addPlannerError
        // addToGroceriesSuccess ||
        // addToGroceriesError ||
        // addToFavoritesSuccess ||
        // addToFavoritesError ||
        // removeFavoriteSuccess ||
        // removeFavoriteError ||
        // removeFromGroceriesSuccess ||
        // removeFromGroceriesError
      ) {
        setTimeout(() => {
          modal.remove();
          if (!isProUser) {
            PostMessage({
              type: "showInterstitialAd"
            });
          }
        }, 1000);
      }
    }, [
      addPlannerSuccess
      // addPlannerError
      // addToGroceriesSuccess,
      // addToGroceriesError,
      // addToGroceriesLoading,
      // addToFavoritesSuccess,
      // addToFavoritesError,
      // removeFavoriteSuccess,
      // removeFavoriteError,
      // removeFromGroceriesSuccess,
      // removeFromGroceriesError
    ]);

    const showConfirmationModal = () =>
      NiceModal.show("planner-confirmation-modal", {
        recipeId,
        recipeHasGroceriesList,
        startDate
      });

    return (
      <BaseModal
        submitLabel="Save"
        onDismiss={() => {
          HapticFeedback();
          modal.remove();
        }}
        onSubmit={() => {
          HapticFeedback();
          addPlanner({
            users: [userId],
            recipes: [recipeId],
            scheduledFor: startDate
          });
          PostMessage({
            type: "schedulePlannerNotification",
            value: {
              title: `Planned Meals`,
              body: `Just grab the ${
                recipeById?.ingredientsList.length
              } ingredients and finish the ${recipeById.title} recipe in just ${
                (recipeById.time * 60) / 60
              } Minutes`,
              scheduledFor: startDate
            }
          });
          sendUserActivity({
            type: "add_recipe_to_planner_list",
            recipe: recipeId
          });
          showConfirmationModal();
        }}
        visible={modal.visible}
        loading={addPlannerLoading ? "onSubmit" : null}
      >
        <DatePicker
          inline
          showTimeSelect
          startDate={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </BaseModal>
    );
  }
);

export default AddRecipeToPlanner;
