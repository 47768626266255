import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {
    customInsetTop: null,
    customInsetBottom: null,
    showHeader: true,
    headerImage: null,
    headerStyle: "default",
    mainActionType: "",
    headerType: "",
    header: null,
    secondaryActionType: "",
    showFooter: true
  }
};

export const toggleNavigation = createAsyncThunk(
  "ui/toggleNavigation",
  async (value) => {
    if (value) {
      // window.StatusBar && window.StatusBar.styleLightContent();
      document.body.classList.add("navigation-opened");
    } else {
      // window.StatusBar && window.StatusBar.styleDefault();
      document.body.classList.remove("navigation-opened");
    }
  }
);

const ui = createSlice({
  name: "ui",
  initialState,
  reducers: {
    changeUiConfig(state, action) {
      state.config = action.payload;
    }
  }
});

export const { changeUiConfig } = ui.actions;

export default ui.reducer;
