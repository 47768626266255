import { createSlice } from "@reduxjs/toolkit";
import { apiProfile } from "./apiProfileSlice";
import { PostMessage } from "../utils";

const initialState = {
  user: null,
  token: localStorage.getItem("jwt")
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload: { user, jwt } }) => {
      localStorage.setItem("jwt", jwt);
      state.user = user;
      state.jwt = localStorage.getItem("jwt") || jwt;
    },
    logOut: (state) => {
      localStorage.removeItem("jwt");
      state.user = null;
      state.jwt = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiProfile.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;

        // PostMessage({
        //   type: "setupPurchases",
        //   value: payload
        // });

        // PostMessage({
        //   type: "checkRequiredPermissions"
        // });
      }
    );
  }
});

export const { setCredentials, logOut } = auth.actions;

export const selectCurrentUser = (state) => state.auth.user;

export const selectCurrentUserToken = (state) => state.auth.token;

export default auth.reducer;
