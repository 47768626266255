import React from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper, Layout, Header, Content } from "../../Components";
import {
  StyledButtonOutlinedSmall,
  StyledBadge,
  StyledBadgeNegative,
  StyledCard
} from "../../Style";
import { ReactComponent as SoupIcon } from "../../Assets/soup.svg";
import { ReactComponent as BasketIcon } from "../../Assets/shopping-basket.svg";
import { useGetRecipesQuery } from "../../Slices/apiRecipesSlice";

const RecipesStatic = () => {
  const navigate = useNavigate();

  const {
    data: recipes,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetRecipesQuery();

  if (isLoading) return null;

  if (isError) return <div>{error}</div>;

  return (
    <Wrapper>
      <Header static />
      <Layout>
        <Content>
          <div className="columns is-centered mt-0">
            <div className="column is-10 py-0">
              <div className="columns">
                {isSuccess &&
                  recipes &&
                  recipes.length &&
                  recipes.map(
                    ({
                      id,
                      time,
                      title,
                      servings,
                      ingredients,
                      cover: {
                        formats: { large, medium, small, thumbnail }
                      }
                    }) => (
                      <div className="column is-4" key={id}>
                        <StyledCard>
                          <div className="card-image">
                            <img
                              src={`${process.env.REACT_APP_API_URL}${small.url}`}
                              alt="Recipe"
                              draggable={false}
                              style={{
                                maxHeight: "200px",
                                objectFit: "cover"
                              }}
                            />
                            <div className="card-image-meta">
                              <StyledBadgeNegative>
                                {time / 60} Minutes
                              </StyledBadgeNegative>
                              <StyledBadge className="ml-a">
                                150 Likes
                              </StyledBadge>
                            </div>
                          </div>
                          <h2>{title}</h2>
                          <div className="card-meta">
                            <div className="card-meta-item">
                              <SoupIcon />
                              {servings} Servings
                            </div>
                            <div className="card-meta-item">
                              <BasketIcon />
                              {ingredients.length} Ingredients
                            </div>
                          </div>
                          <StyledButtonOutlinedSmall
                            className="mb-0"
                            onClick={() => {
                              navigate(`/recipes/${id}`);
                            }}
                          >
                            View
                          </StyledButtonOutlinedSmall>
                        </StyledCard>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Wrapper>
  );
};

export default RecipesStatic;
