import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StyledPaywall from "./style";
import {
  StyledBadge,
  StyledBadgeNegative,
  StyledButton,
  StyledButtonNegativeOutlinedSmall
} from "../../Style";
import { selectCurrentUser } from "../../Slices/authSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import { selectProduct } from "../../Slices/productSlice";
import {
  setPaywallViewed,
  selectPaywallViewd
} from "../../Slices/generalSlice";
import { HapticFeedback, PostMessage } from "../../utils";
import { ReactComponent as CheckSVG } from "../../Assets/valid-field.svg";

const Paywall = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const product = useSelector(selectProduct);

  const paywallViewed = useSelector(selectPaywallViewd);

  const [paywallActive, setPaywallActive] = useState(false);

  const [makePurchaseLoading, setMakePurchaseLoading] = useState(false);

  const [sendUserActivity] = useSendUserActivityMutation();

  useEffect(() => {
    if (user?.role?.name === "Standard User" && !paywallViewed)
      setPaywallActive(true);
    if (user?.role?.name === "Pro User" && !paywallViewed)
      setPaywallActive(false);
  }, [user, paywallViewed]);

  return paywallActive ? (
    <StyledPaywall>
      <div className="dialog-card mt-auto mb-auto">
        <StyledButtonNegativeOutlinedSmall
          className="mb-0 skip-button"
          onClick={(e) => {
            e.stopPropagation();
            HapticFeedback();
            setPaywallActive(false);
            sendUserActivity({
              type: "skipped_paywall"
            });
            PostMessage({
              type: "showInterstitialAd"
            });
            dispatch(setPaywallViewed(true));
          }}
        >
          Skip
        </StyledButtonNegativeOutlinedSmall>
        <h2>
          Upgrade to <StyledBadge style={{ fontSize: "20px" }}>PRO</StyledBadge>
        </h2>
        <div className="feature" style={{ borderTop: "1px solid #f5f7fb" }}>
          <h3>
            Voice Commands
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
        <div className="feature">
          <h3>
            Guided Instructions
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
        <div className="feature">
          <h3>
            Advanced Filters
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
        <div className="feature">
          <h3>
            Shopping Lists
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
        <div className="feature">
          <h3>
            Meal Planner
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
        <div className="feature">
          <h3>
            Nutrition Info
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
        <div className="feature">
          <h3>
            Favorite Recipes
            <StyledBadgeNegative style={{ marginLeft: "auto", padding: "0" }}>
              <CheckSVG />
            </StyledBadgeNegative>
          </h3>
        </div>
      </div>
      <h6 className="mb-auto">
        {product?.price
          ? product.price.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) +
            " " +
            product?.currencyCode
          : "$2.99"}{" "}
        Month
      </h6>
      <StyledButton
        className="mb-0"
        loading={makePurchaseLoading.toString()}
        disabled={makePurchaseLoading}
        onClick={(e) => {
          e.stopPropagation();
          HapticFeedback();
          setMakePurchaseLoading(true);
          PostMessage({
            type: "makePurchase"
          });
          PostMessage({
            type: "sendFirebaseAnalyticsEvent",
            value: {
              name: "logBeginCheckout"
            }
          });
          sendUserActivity({
            type: "begin_checkout"
          });
          dispatch(setPaywallViewed(true));
        }}
      >
        Try 1 Month Free
      </StyledButton>
    </StyledPaywall>
  ) : null;
};

export default Paywall;
