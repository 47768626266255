import styled, { css } from "styled-components";

const StyledContent = styled.div`
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  padding: 96px 14px 87px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  touch-action: pan-y !important;
  padding-top: calc(86px + var(--safe-area-inset-top));
  padding-bottom: calc(77px + var(--safe-area-inset-bottom));

  ${({ customInsetTop }) =>
    customInsetTop &&
    css`
      padding-top: calc(86px + ${customInsetTop}px);
    `}

  ${({ showFooter, customInsetTop }) =>
    showFooter === "false" &&
    css`
      padding-bottom: calc(
        14px +
          ${customInsetTop
            ? customInsetTop + "px"
            : "var(--safe-area-inset-bottom)"}
      );
    `}
`;

export default StyledContent;
