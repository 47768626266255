import styled, { css } from "styled-components";
import { ButtonBase } from "@mui/material";

export const StyledSpinner = styled.div`
  margin: 0 auto;
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 10em;
  height: 10em;

  ${({ color }) =>
    color &&
    css`
      border-left-color: ${color};
    `}

  :after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Button = (props) => (
  <ButtonBase disabled={props.loading === "true"} type="submit" {...props}>
    {props.loading === "true" ? (
      <StyledSpinner className="spinner" />
    ) : (
      props.children
    )}
  </ButtonBase>
);

const StyledButton = styled(Button)`
  && {
    width: 100%;
    min-height: 56px;
    margin: 0 0 10px;
    cursor: pointer;
    outline: 0;
    border: 1px solid #65c18c;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fff;
    border-radius: 28px;
    background-color: #65c18c;
    box-shadow: 0px 3px 10px rgba(17, 17, 17, 0.2);

    a {
      color: inherit;
      text-decoration: none;
    }

    svg {
      min-width: 20px;
      display: block;
      width: auto;
      height: 18px;
      fill: #fff;
      color: #fff;
      margin: 0 15px 0 0;
    }

    ${({ danger }) =>
      danger &&
      css`
        border-color: #f94144;
        background-color: #f94144;
      `}
  }
`;

const StyledActionButton = styled(StyledButton)`
  && {
    border-radius: 0;
    background: transparent;
    border: 0;
    box-shadow: none;
    border-bottom: 1px solid #f5f7fb;
    color: #404852;
    text-align: left;
    padding: 22px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > div:not(.spinner) {
      border-radius: 6px;
      border: 1px solid #65c18c;
      width: 32px;
      height: 32px;
      margin: 0 15px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .spinner {
      width: 7.338em;
      height: 7.338em;
      margin: 0;
      border-left-color: #65c18c;
    }

    .MuiTouchRipple-rippleVisible {
      background-color: #65c18c !important;
    }

    svg {
      fill: #65c18c;
      color: #65c18c;
    }
  }
`;

const StyledButtonSmall = styled(StyledButton)`
  && {
    min-height: 36px;
    width: auto;
    font-size: 14px;
    padding: 0 20px;
  }
`;

const StyledButtonSmallest = styled(StyledButton)`
  && {
    min-height: 28px;
    line-height: 28px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 400;
  }
`;

const StyledButtonNegative = styled(StyledButton)`
  && {
    color: #fff;
    border-color: #454545;
    background-color: #454545;
    box-shadow: none;
  }
`;

const StyledButtonNegativeOutlined = styled(StyledButtonNegative)`
  && {
    color: #454545;
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledButtonNegativeSmall = styled(StyledButtonNegative)`
  && {
    width: auto;
    line-height: 36px;
    min-height: 36px;
    font-size: 14px;
    padding: 0 20px;
  }
`;

const StyledButtonNegativeOutlinedSmall = styled(StyledButtonNegative)`
  && {
    min-height: 36px;
    width: auto;
    color: #454545;
    border-color: #454545;
    font-size: 14px;
    padding: 0 20px;
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledButtonDark = styled(StyledButton)`
  && {
    color: #454545;
    border-color: #454545;
    background-color: transparent;
    box-shadow: none;

    > .spinner {
      border-top: 1.1em solid rgba(64, 72, 82, 0.2);
      border-right: 1.1em solid rgba(64, 72, 82, 0.2);
      border-bottom: 1.1em solid rgba(64, 72, 82, 0.2);
      border-left: 1.1em solid rgb(64, 72, 82);
    }
  }
`;

const StyledButtonBlack = styled(StyledButton)`
  && {
    color: #fff;
    border-color: #000;
    background-color: #000;
    box-shadow: none;

    > .spinner {
      border-top: 1.1em solid rgba(64, 72, 82, 0.2);
      border-right: 1.1em solid rgba(64, 72, 82, 0.2);
      border-bottom: 1.1em solid rgba(64, 72, 82, 0.2);
      border-left: 1.1em solid rgb(64, 72, 82);
    }

    svg {
      fill: #fff;
      color: #fff;
    }
  }
`;

const StyledButtonWhite = styled(StyledButton)`
  && {
    color: #000;
    border-color: #fff;
    background-color: #fff;

    > .spinner {
      border-top: 1.1em solid rgba(64, 72, 82, 0.2);
      border-right: 1.1em solid rgba(64, 72, 82, 0.2);
      border-bottom: 1.1em solid rgba(64, 72, 82, 0.2);
      border-left: 1.1em solid rgb(64, 72, 82);
    }

    svg {
      fill: #000;
      color: #000;
    }
  }
`;

const StyledButtonOutlined = styled(StyledButton)`
  && {
    color: #65c18c;
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledButtonOutlinedSmall = styled(StyledButton)`
  && {
    min-height: 36px;
    width: auto;
    color: #65c18c;
    font-size: 14px;
    padding: 0 20px;
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledButtonIcon = styled.button`
  && {
    outline: 0;
    border: 0;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    svg {
      display: block;
      width: auto;
      height: 18px;
      fill: #404852;
      color: #404852;
      transition: fill 400ms ease-in-out;
    }

    a:hover,
    a.active {
      svg {
        fill: #65c18c;
      }
    }
  }
`;

const StyledButtonSquareWhite = styled(StyledButton)`
  && {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 46px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
    line-height: 1;
    text-align: left;
    color: #404852;
    border-radius: 6px;
    outline: 0;
    margin: 0 0 10px;
    border: 0;
    padding: 0 16px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(17, 17, 17, 0.1);

    svg {
      width: 14px;
      min-width: 14px;
      height: auto;
      fill: #ff617c;
      margin: 0 10px 0 0;
    }

    > .spinner {
      width: 7.338em;
      height: 7.338em;
      margin: 0;
      border-left-color: #404852;
    }
  }
`;

export {
  StyledButton,
  StyledActionButton,
  StyledButtonSmall,
  StyledButtonSmallest,
  StyledButtonNegativeSmall,
  StyledButtonNegative,
  StyledButtonDark,
  StyledButtonBlack,
  StyledButtonWhite,
  StyledButtonOutlined,
  StyledButtonOutlinedSmall,
  StyledButtonIcon,
  StyledButtonSquareWhite,
  StyledButtonNegativeOutlined,
  StyledButtonNegativeOutlinedSmall
};
