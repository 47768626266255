import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null
};

const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, { payload }) => {
      state.data = payload;
    }
  }
});

export const { setProduct } = product.actions;

export const selectProduct = (state) => state.product.data;

export default product.reducer;
