import styled from "styled-components";
import { Toggle } from "react-toggle-component";

const StyledSwitch = styled(Toggle)`
  width: auto;
  margin: 0;
  height: auto;

  input[type="checkbox"] {
    display: none;

    &:checked ~ label {
      background-color: #454545;
      &:after {
        left: 38px;
        margin: 0;
        background-color: #fff;
      }
    }

    + label {
      position: relative;
      display: block;
      width: 67px;
      height: 32px;
      border-radius: 32px;
      transition: all 260ms ease-in-out;
      background-color: #ebeff6;

      &:before {
        display: none;
      }

      &:after {
        position: absolute;
        top: 4px;
        left: 4px;
        display: block;
        content: "";
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin: 0;
        border-radius: 100%;
        transition: all 260ms ease-in-out;
        background-color: #454545;
      }
    }
  }
`;

export { StyledSwitch };
