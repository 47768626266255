import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiPayments = createApi({
  reducerPath: "apiPayments",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    sendAppleIAPClientConfirmation: builder.mutation({
      query: (body) => ({
        url: "/payment-events/apple-iap-client-confirmation",
        method: "POST",
        body: body
      })
    }),
    sendAndroidIAPClientConfirmation: builder.mutation({
      query: (body) => ({
        url: "/payment-events/android-iap-client-confirmation",
        method: "POST",
        body: body
      })
    })
  })
});

export const {
  useSendAppleIAPClientConfirmationMutation,
  useSendAndroidIAPClientConfirmationMutation
} = apiPayments;
