import styled, { keyframes } from "styled-components";

const StyledProgress = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;

  p {
    position: relative;
    z-index: 1;
    color: rgba(120, 110, 255, 1);
    font-size: 18px;
  }

  .progress-container {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #f5f7fb;
    border-radius: 10px;
    overflow: hidden;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background: linear-gradient(30deg, #aaffa9, #11ffbd);
    border-radius: 10px;
    transition: 1s ease;
  }

  .progress-knob {
    padding: 0 10px;
    min-width: 6px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 12px;
    line-height: 1;
    color: #454545;
    position: absolute;
    background: #fff;
    border: 1px solid rgb(64 72 82 / 7%);
    box-shadow: 0px 2px 10px rgb(173 179 191 / 15%);
    border-radius: 6px;
    transform: translateX(-50%);
  }
`;

const StyledProgressLabel = styled.div`
  font-family: "Nanum Gothic", sans-serif;
  font-size: 12px;
  line-height: 40px;
  text-align: left;
  text-transform: capitalize;
  color: #454545;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 10px;
    text-transform: lowercase;
  }
`;

export { StyledProgress, StyledProgressLabel };
