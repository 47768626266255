import { createSlice } from "@reduxjs/toolkit";
import { detect } from "detect-browser";
const browser = detect();

const userAgent = navigator.userAgentData;

const initialState = {
  OS: window?.ReactNativeWebView ? null : browser?.os,
  constants: window?.ReactNativeWebView
    ? {}
    : {
        version: browser?.version,
        type: browser?.type,
        name: browser?.name,
        mobile: userAgent?.mobile,
        web: !!userAgent
      },
  buildNumber: null,
  appVersion: null
};

const platform = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setPlatform: (state, { payload }) => {
      state.OS = payload.OS;
      state.constants = payload?.constants;
      state.buildNumber = payload?.buildNumber;
      state.appVersion = payload?.appVersion;
    }
  }
});

export const { setPlatform } = platform.actions;

export const selectPlatform = (state) => state.platform;

export const selectPlatformOS = (state) => state.platform.OS;

export const selectPlatformConstants = (state) => state.platform.constants;

export default platform.reducer;
