import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiPlannerSlice = createApi({
  reducerPath: "apiPlannerSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getPlanners: builder.query({
      query: () => `/planners`,
      providesTags: ["Planner"]
    }),
    addPlanner: builder.mutation({
      query: (data) => ({
        url: "/planners",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Planner", "RecipePlanned"]
    }),
    addRecipeToPlanner: builder.mutation({
      query: (id, data) => ({
        url: `/planners/${id}/add-recipe`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["Planner", "RecipePlanned"]
    }),
    removeRecipeFromPlanner: builder.mutation({
      query: ({ plannerId, recipeId }) => ({
        url: `/planners/${plannerId}/remove-recipe/${recipeId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Planner", "RecipePlanned"]
    }),
    removePlanner: builder.mutation({
      query: (id) => ({
        url: `/planner/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Planner", "RecipePlanned"]
    }),
    recipePlanned: builder.query({
      query: (recipeId) => `/planners/recipe-planned/${recipeId}`,
      providesTags: ["RecipePlanned"]
    })
  })
});

export const {
  useGetPlannersQuery,
  useAddPlannerMutation,
  useAddRecipeToPlannerMutation,
  useRemoveRecipeFromPlannerMutation,
  useRemovePlannerMutation,
  useRecipePlannedQuery
} = apiPlannerSlice;
