import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiUserActivity = createApi({
  reducerPath: "apiUserActivity",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    sendUserActivity: builder.mutation({
      async queryFn(_arg, { getState }, _extraOptions, fetchWithBQ) {
        const state = getState();
        await fetchWithBQ({
          url: `/user-activities`,
          method: "POST",
          body: {
            ..._arg,
            OS: state?.platform?.OS,
            data: { ..._arg?.data, ...state?.platform?.constants },
            buildNumber: state?.platform?.buildNumber,
            appVersion: state?.platform?.appVersion
          }
        });
      }
    })
  })
});

export const { useSendUserActivityMutation } = apiUserActivity;
