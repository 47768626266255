import React, { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import StyledConfirmEmailAddress from "./style";
import { StyledCard, StyledButton, StyledLink } from "../../Style";
import { useSendEmailConfirmationMutation } from "../../Slices/apiAuthSlice";
import { HapticFeedback } from "../../utils";
import TamarinLogo from "../../Assets/tamarin-logo.jpg";

const ConfirmEmailAddress = () => {
  const location = useLocation();

  const [sendEmailConfirmation, { isLoading, isSuccess, error }] =
    useSendEmailConfirmationMutation();

  const userEmail = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    if (isSuccess) {
      NiceModal.show("info-modal", {
        heading: "Congratulations.",
        description:
          "We sent you a new e-mail that includes a confirmation link.",
        submitLabel: "Close",
        hideDismiss: true
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      NiceModal.show("info-modal", {
        heading: "We have a little problem.",
        description: "Please try again.",
        dismissLabel: "Close",
        warning: true
      });
    }
  }, [error]);

  return (
    <Fragment>
      <StyledConfirmEmailAddress>
        <img
          src={TamarinLogo}
          style={{
            width: "180px",
            height: "180px",
            display: "block",
            borderRadius: "16px",
            margin: "0 auto 80px"
          }}
        />
        <StyledCard className="mb-0">
          <StyledLink
            to={"/"}
            className="mb-0"
            style={{ textAlign: "right" }}
            onClick={() => HapticFeedback()}
          >
            Skip
          </StyledLink>
          <h1 style={{ fontSize: "18px", lineHeight: "30px" }}>
            Please verify your
            <br />
            email address to access <br />
            all of Tamarin features.
          </h1>
          <p style={{ margin: 0, lineHeight: 2 }}>
            We sent you an e-mail that includes a confirmation link, please
            click on the link in order to confirm your account. If you didn't
            receive any email you can resend the email with the button below.
          </p>
          {userEmail && (
            <StyledButton
              className="mt-20 mb-0"
              loading={isLoading.toString()}
              onClick={async () => {
                HapticFeedback();
                await sendEmailConfirmation({ email: userEmail });
              }}
            >
              Resend
            </StyledButton>
          )}
        </StyledCard>
      </StyledConfirmEmailAddress>
    </Fragment>
  );
};

export default ConfirmEmailAddress;
