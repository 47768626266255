import styled from "styled-components";

const StyledLayout = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;
`;

export default StyledLayout;
