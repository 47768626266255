import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  data: []
};

const recipes = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    setRecipes: (state, { payload }) => {
      state.data = payload;
    }
  }
});

export const { setRecipes } = recipes.actions;

export const selectRecipes = (state) => state?.recipes?.data;

export const selectRecipeById = (state, id) =>
  state?.recipes?.data?.filter((recipe) => recipe._id === id)[0];

export default recipes.reducer;
