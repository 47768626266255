import React from "react";
import {
  AppSwipteHandler,
  Navigation,
  Wrapper,
  Layout,
  Header,
  Content,
  Footer
} from "../index";

const PrivateRouteLayout = ({ children }) => (
  <AppSwipteHandler>
    <Navigation />
    <Wrapper>
      <Header />
      <Layout>
        <Content id={"content-container"}>{children}</Content>
      </Layout>
      <Footer />
    </Wrapper>
  </AppSwipteHandler>
);

export default PrivateRouteLayout;
