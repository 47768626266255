import styled from "styled-components";

const StyledWelcome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  padding-top: calc(34px + var(--safe-area-inset-top));
  padding-bottom: calc(34px + var(--safe-area-inset-bottom));

  > img {
    top: calc(10px + var(--safe-area-inset-top)) !important;
  }

  .slick-dots li button:before {
    font-size: 8px;
  }

  .slick-dots li.slick-active button:before {
    color: #65c18c;
  }
`;

export default StyledWelcome;
