import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cookingSessionsDialogViewed: false,
  paywallViewed: false,
  skippedWelcomeScreen: false,
  navigatedToRecipeFromIndex: null
};

const general = createSlice({
  name: "general",
  initialState,
  reducers: {
    setCookingSessionsDialogViewed(state, action) {
      state.cookingSessionsDialogViewed = action.payload;
    },
    setPaywallViewed(state, action) {
      state.paywallViewed = action.payload;
    },
    setSkippedWelcomeScreen(state, action) {
      state.skippedWelcomeScreen = action.payload;
    },
    setNavigatedToRecipeFromIndex(state, action) {
      state.navigatedToRecipeFromIndex = action.payload;
    }
  }
});

export const {
  setCookingSessionsDialogViewed,
  setPaywallViewed,
  setSkippedWelcomeScreen,
  setNavigatedToRecipeFromIndex
} = general.actions;

export const selectCookingSessionDialogViewd = (state) =>
  state.general.cookingSessionsDialogViewed;

export const selectPaywallViewd = (state) => state.general.paywallViewed;

export const selectSkippedWelcomeScreen = (state) =>
  state.general.skippedWelcomeScreen;

export const selectNavigatedToRecipeFromIndex = (state) =>
  state.general.navigatedToRecipeFromIndex;

export default general.reducer;
