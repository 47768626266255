import React, { useState, useEffect } from "react";
import StyledIncrementor from "./style";
import { ReactComponent as MinusIcon } from "../../Assets/minus.svg";
import { ReactComponent as PlusIcon } from "../../Assets/plus.svg";
import { HapticFeedback } from "../../utils";

const Incrementor = (props) => {
  const [state, setState] = useState({ amount: props?.amount || 0 });

  useEffect(() => {});

  const decrease = () => {
    HapticFeedback();
    state?.amount > 1 &&
      setState({ amount: state?.amount - (props?.increment || 1) });
    props?.changeCallback(state?.amount - (props?.increment || 1));
  };

  const increase = () => {
    HapticFeedback();
    state?.amount < (props?.maxAmount || 20) &&
      setState({ amount: state?.amount + (props?.increment || 1) });
    props?.changeCallback(state?.amount + (props?.increment || 1));
  };

  return (
    <StyledIncrementor className={props?.className} small={props?.small}>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          decrease(e);
        }}
        className="control"
        disabled={state?.amount <= 1}
      >
        <MinusIcon />
      </button>
      <span>
        {props?.prefix} {state?.amount} {props?.sufix}
      </span>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          increase(e);
        }}
        className="control"
        disabled={state?.amount >= 20}
      >
        <PlusIcon />
      </button>
    </StyledIncrementor>
  );
};

export default Incrementor;
