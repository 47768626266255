export const PostMessage = ({ type, value }) =>
  type
    ? window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actionType: type,
          actionValue: value
        })
      )
    : null;

export const HapticFeedback = (value) =>
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({
      actionType: "triggerHapticFeedback",
      actionValue: value || "impactMedium"
    })
  );
