import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";

export const apiRecipes = createApi({
  reducerPath: "apiRecipes",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  keepUnusedDataFor: 600,
  endpoints: (builder) => ({
    getRecipes: builder.query({
      query: (data) => {
        const query = qs.stringify(data);

        return `/recipes${query ? `?${query}` : ""}`;
      },
      providesTags: ["Recipes"],
      invalidatesTags: ["RecipesCount"]
    }),
    getRecipesCount: builder.query({
      query: (data) => {
        const query = qs.stringify(data);

        return `/recipes/count${query ? `?${query}` : ""}`;
      },
      providesTags: ["RecipesCount"]
    }),
    getRecipe: builder.query({
      query: (recipeId) => `/recipes/${recipeId}`
    })
  })
});

export const resetApiRecipesState = apiRecipes.util.resetApiState;

export const {
  useGetRecipesQuery,
  useLazyGetRecipesQuery,
  useGetRecipesCountQuery,
  useLazyGetRecipesCountQuery,
  useGetRecipeQuery,
  useLazyGetRecipeQuery
} = apiRecipes;

export default apiRecipes;
