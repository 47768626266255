import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import BaseModal from "./BaseModal";
import { HapticFeedback } from "../../utils";

const InfoModal = NiceModal.create((props) => {
  const modal = useModal();

  const [loading, setLoading] = useState(null);

  return (
    <BaseModal
      loading={loading}
      {...props}
      onDismiss={() => {
        HapticFeedback();
        props?.onDismiss && props.onDismiss();
        modal.remove();
      }}
      onSubmit={() => {
        setLoading("onSubmit");
        HapticFeedback();
        props?.onSubmit && props.onSubmit();
      }}
      visible={modal.visible}
    >
      {props?.content}
    </BaseModal>
  );
});

export default InfoModal;
