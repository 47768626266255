import React, { Fragment } from "react";
import { useField } from "formik";
import { StyledSwitch } from "./style";
import { HapticFeedback } from "../../../utils";

const Switch = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Fragment>
      <StyledSwitch
        {...props}
        checked={field.value}
        onToggle={(e) => {
          HapticFeedback();
          field.onChange(e);
        }}
      />
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </Fragment>
  );
};

export { Switch };
