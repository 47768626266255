import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  start: 0,
  limit: 10,
  title: null,
  includeIngredients: [],
  excludeIngredients: [],
  servings: 4,
  time: null,
  calories: null,
  diet: [],
  dishType: [],
  course: [],
  cuisine: []
};

const filter = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilter: (
      state,
      {
        payload: {
          start,
          limit,
          title,
          includeIngredients,
          excludeIngredients,
          servings,
          time,
          calories,
          diet,
          dishType,
          course,
          cuisine
        }
      }
    ) => {
      if (start !== undefined) state.start = start;
      if (limit) state.limit = limit;
      if (title) state.title = title;
      if (includeIngredients) state.includeIngredients = includeIngredients;
      if (excludeIngredients) state.excludeIngredients = excludeIngredients;
      if (servings) state.servings = servings;
      if (time) state.time = time;
      if (calories) state.calories = calories;
      if (diet) state.diet = diet;
      if (dishType) state.dishType = dishType;
      if (course) state.course = course;
      if (cuisine) state.cuisine = cuisine;
    }
  }
});

export const { setFilter } = filter.actions;

export const selectFilter = (state) => state.filter;

export default filter.reducer;
