import React, { Fragment } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import NiceModal from "@ebay/nice-modal-react";
import StyledResetPassword from "./style";
import { Form, Field } from "../../Components";
import {
  StyledCard,
  StyledButton,
  StyledButtonIcon,
  StyledForm,
  StyledFormGroup
} from "../../Style";
import { useResetPasswordMutation } from "../../Slices/apiAuthSlice";
import { setCredentials, selectCurrentUser } from "../../Slices/authSlice";
import { HapticFeedback } from "../../utils";
import { ReactComponent as ChevronLeftIcon } from "../../Assets/chevron-left.svg";
import TamarinLogo from "../../Assets/tamarin-logo.jpg";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password too short.")
    .required("Password required."),
  passwordConfirmation: Yup.string()
    .test("password", "Passwords must match", function (value) {
      return this.parent.password === value;
    })
    .min(8, "Password too short.")
    .required("Password required.")
});

const ResetPassword = () => {
  const user = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const resetCode = new URLSearchParams(location.search).get("code");

  return user ? (
    <Navigate to="/" />
  ) : (
    <Fragment>
      <StyledResetPassword>
        <StyledButtonIcon
          style={{
            width: "40px",
            lineHeight: 1,
            boxShadow: "0px 2px 10px rgb(173 179 191 / 15%)",
            borderRadius: "12px"
          }}
          onClick={() => {
            HapticFeedback();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </StyledButtonIcon>
        <img
          src={TamarinLogo}
          style={{
            width: "180px",
            height: "180px",
            display: "block",
            borderRadius: "16px",
            margin: "auto auto 0"
          }}
        />
        <h1
          style={{
            marginTop: "auto",
            marginBottom: "30px",
            fontSize: "42px"
          }}
        >
          Reset password
        </h1>
        <Form
          initialValues={{
            password: "",
            passwordConfirmation: ""
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={async (values) => {
            try {
              values.code = resetCode;
              const { data, error } = await resetPassword(values);
              if (data) {
                dispatch(setCredentials(data));
              }
              if (
                error &&
                error.data.message[0].messages[0].message ===
                  "Passwords do not match."
              ) {
                NiceModal.show("info-modal", {
                  heading: "We have a little problem.",
                  description: "Passwords do not match.",
                  dismissLabel: "Close",
                  warning: true
                });
              }
              if (
                error &&
                error?.data?.message[0]?.messages[0]?.message ===
                  "Incorrect code provided."
              ) {
                NiceModal.show("info-modal", {
                  heading: "We have a little problem.",
                  description:
                    "It looks like this link expired, please try again with a new one.",
                  dismissLabel: "Close",
                  warning: true
                });
              }

              if (error && error?.status === "FETCH_ERROR") {
                NiceModal.show("info-modal", {
                  heading: "Oops!",
                  description:
                    "Something went wrong. We are not sure yet and will examine this case. Try again.",
                  dismissLabel: "OK",
                  warning: true
                });
              }
            } catch (err) {
              console.log(err);
            }
          }}
          style={{ height: "100%" }}
        >
          <StyledCard className="mb-0">
            <StyledForm>
              <StyledFormGroup>
                <Field name="password" type="password" placeholder="Password" />
              </StyledFormGroup>
              <StyledFormGroup>
                <Field
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Confirm Password"
                />
              </StyledFormGroup>
            </StyledForm>
          </StyledCard>
          <StyledButton
            className="mt-20 mb-0"
            loading={isLoading.toString()}
            onClick={() => HapticFeedback()}
          >
            Reset Password
          </StyledButton>
        </Form>
      </StyledResetPassword>
    </Fragment>
  );
};

export default ResetPassword;
