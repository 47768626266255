import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiGroceries = createApi({
  reducerPath: "apiGroceries",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwt") || getState().auth.jwt;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getGroceries: builder.query({
      query: (query) => `/shopping-lists${query || ""}`,
      providesTags: ["ShoppingLists"]
    }),
    addToGroceries: builder.mutation({
      query: (data) => ({
        url: "/shopping-lists",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["ShoppingLists", "RecipeHasShoppingList"]
    }),
    updateGroceries: builder.mutation({
      query: ({ id, data }) => ({
        url: `/shopping-lists/${id}`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["ShoppingLists", "RecipeHasShoppingList"]
    }),
    complete: builder.mutation({
      query: () => ({
        url: `/shopping-lists/complete`,
        method: "POST"
      }),
      invalidatesTags: ["ShoppingLists"]
    }),
    deleteAll: builder.mutation({
      query: () => ({
        url: `/shopping-lists/delete-all`,
        method: "POST"
      }),
      invalidatesTags: ["ShoppingLists", "RecipeHasShoppingList"]
    }),
    removeFromGroceries: builder.mutation({
      query: (id) => ({
        url: `/shopping-lists/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["ShoppingLists", "RecipeHasShoppingList"]
    }),
    recipeHasGroceriesList: builder.query({
      query: (recipeId) =>
        `/shopping-lists/recipe-has-groceries-list/${recipeId}`,
      providesTags: ["RecipeHasShoppingList"]
    })
  })
});

export const updateQueryData = apiGroceries.util.updateQueryData;

export const {
  useGetGroceriesQuery,
  useLazyGetGroceriesQuery,
  useAddToGroceriesMutation,
  useUpdateGroceriesMutation,
  useCompleteMutation,
  useDeleteAllMutation,
  useRemoveFromGroceriesMutation,
  useRecipeHasGroceriesListQuery
} = apiGroceries;
