import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHasRoles } from "react-simple-rbac";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import BaseModal from "./BaseModal";
import { HapticFeedback, PostMessage } from "../../utils";
import { DatePicker } from "..";
import { selectCurrentUser } from "../../Slices/authSlice";
import { selectRecipeById } from "../../Slices/recipeSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import {
  useGetPlannersQuery,
  useAddPlannerMutation,
  useAddRecipeToPlannerMutation
} from "../../Slices/apiPlannerSlice";
import { useAddToGroceriesMutation } from "../../Slices/apiGroceriesSlice";

const PlannerConfirmation = NiceModal.create(
  ({ recipeId, recipeHasGroceriesList, startDate }) => {
    const modal = useModal();

    const isProUser = useHasRoles(["Pro User"]);

    const [sendUserActivity] = useSendUserActivityMutation();

    const { id: userId } = useSelector(selectCurrentUser);

    const recipeById = useSelector((state) =>
      selectRecipeById(state, recipeId)
    );

    const [
      addToGroceries,
      {
        isLoading: addToGroceriesLoading,
        isSuccess: addToGroceriesSuccess,
        isError: addToGroceriesError
      }
    ] = useAddToGroceriesMutation();

    useEffect(() => {
      if (addToGroceriesSuccess) {
        setTimeout(() => {
          modal.remove();
          if (!isProUser) {
            PostMessage({
              type: "showInterstitialAd"
            });
          }
        }, 1000);
      }
    }, [addToGroceriesSuccess]);

    return (
      <BaseModal
        heading="Congratulations."
        description={`Recipe ${
          recipeById.title
        } was added to your Meal Plan and scheduled for ${new Date(
          startDate
        ).toLocaleString()}`}
        hideSubmit={recipeHasGroceriesList}
        dismissLabel="Close"
        submitLabel="Add to groceries list"
        onDismiss={() => {
          HapticFeedback();
          modal.remove();
        }}
        onSubmit={() => {
          HapticFeedback();
          addToGroceries({
            users: [userId],
            recipe: recipeId
          });
          sendUserActivity({
            type: "add_recipe_to_shopping_list",
            recipe: recipeId
          });
        }}
        visible={modal.visible}
        loading={addToGroceriesLoading ? "onSubmit" : null}
      />
    );
  }
);

export default PlannerConfirmation;
