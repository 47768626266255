import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHasRoles } from "react-simple-rbac";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import BaseModal from "./BaseModal";
import { StyledActionButton } from "../../Style";
import { HapticFeedback, PostMessage } from "../../utils";
import { ReactComponent as ShoppingBasketIcon } from "../../Assets/shopping-basket.svg";
import { ReactComponent as CalendarIcon } from "../../Assets/calendar-alt.svg";
import { ReactComponent as BookmarkSolid } from "../../Assets/bookmark-solid.svg";
import { selectCurrentUser } from "../../Slices/authSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import {
  useRecipePlannedQuery,
  useRemoveRecipeFromPlannerMutation
} from "../../Slices/apiPlannerSlice";
import {
  useRecipeHasGroceriesListQuery,
  useAddToGroceriesMutation,
  useRemoveFromGroceriesMutation
} from "../../Slices/apiGroceriesSlice";
import {
  useFavoriteRecipeQuery,
  useAddToFavoritesMutation,
  useRemoveFavoriteMutation
} from "../../Slices/apiFavoritesSlice";

const RecipeOptions = NiceModal.create(({ recipeId }) => {
  const modal = useModal();

  const isProUser = useHasRoles(["Pro User"]);

  const { id: userId } = useSelector(selectCurrentUser);

  const [sendUserActivity] = useSendUserActivityMutation();

  const { data: recipePlanned, isLoading: recipePlannedLoading } =
    useRecipePlannedQuery(recipeId, {
      refetchOnMountOrArgChange: true
    });

  const {
    data: recipeHasGroceriesList,
    isLoading: recipeHasGroceriesListLoading
  } = useRecipeHasGroceriesListQuery(recipeId, {
    refetchOnMountOrArgChange: true
  });

  const { data: favoriteRecipe, isLoading: favoriteRecipeLoading } =
    useFavoriteRecipeQuery(recipeId, {
      refetchOnMountOrArgChange: true
    });

  const [
    removeRecipeFromPlanner,
    {
      isLoading: removeRecipeFromPlannerIsLoading,
      isSuccess: removeRecipeFromPlannerIsSuccess,
      isError: removeRecipeFromPlannerIsError
    }
  ] = useRemoveRecipeFromPlannerMutation();

  const [
    addToGroceries,
    {
      isLoading: addToGroceriesLoading,
      isSuccess: addToGroceriesSuccess,
      isError: addToGroceriesError
    }
  ] = useAddToGroceriesMutation();

  const [
    removeFromGroceries,
    {
      isLoading: removeFromGroceriesLoading,
      isSuccess: removeFromGroceriesSuccess,
      isError: removeFromGroceriesError
    }
  ] = useRemoveFromGroceriesMutation();

  const [
    addToFavorites,
    {
      isLoading: addToFavoritesLoading,
      isSuccess: addToFavoritesSuccess,
      isError: addToFavoritesError
    }
  ] = useAddToFavoritesMutation();

  const [
    removeFavorite,
    {
      isLoading: removeFavoriteLoading,
      isSuccess: removeFavoriteSuccess,
      isError: removeFavoriteError
    }
  ] = useRemoveFavoriteMutation();

  useEffect(() => {
    if (
      addToGroceriesSuccess ||
      addToGroceriesError ||
      addToFavoritesSuccess ||
      addToFavoritesError ||
      removeRecipeFromPlannerIsSuccess ||
      removeFavoriteSuccess ||
      removeFavoriteError ||
      removeFromGroceriesSuccess ||
      removeFromGroceriesError
    ) {
      setTimeout(() => {
        modal.remove();
        if (!isProUser) {
          PostMessage({
            type: "showInterstitialAd"
          });
        }
      }, 1000);
    }
  }, [
    addToGroceriesSuccess,
    addToGroceriesError,
    addToGroceriesLoading,
    addToFavoritesSuccess,
    addToFavoritesError,
    removeRecipeFromPlannerIsSuccess,
    removeFavoriteSuccess,
    removeFavoriteError,
    removeFromGroceriesSuccess,
    removeFromGroceriesError
  ]);

  return (
    <BaseModal
      wrapperClassName="p-0"
      hideActions
      hideDismiss
      hideSubmit
      onDismiss={() => {
        HapticFeedback();
        modal.remove();
      }}
      visible={modal.visible}
      loading={
        recipePlannedLoading &&
        recipeHasGroceriesListLoading &&
        favoriteRecipeLoading
          ? "modal"
          : null
      }
    >
      {isProUser && (
        <StyledActionButton
          onClick={(e) => {
            HapticFeedback();
            if (recipePlanned) {
              sendUserActivity({
                type: "remove_recipe_from_planner_list",
                recipe: recipeId
              });
              removeRecipeFromPlanner({
                plannerId: recipePlanned?.plannerId,
                recipeId: recipeId
              });
            } else {
              modal.remove();
              NiceModal.show("add-recipe-to-planner-modal", {
                recipeId,
                recipeHasGroceriesList
              });
            }
          }}
          className="mb-0"
          loading={
            (removeRecipeFromPlannerIsLoading || recipePlannedLoading) && "true"
          }
        >
          <CalendarIcon />
          {recipePlanned ? "Remove from planner" : "Add to planner"}
        </StyledActionButton>
      )}
      {isProUser && (
        <StyledActionButton
          onClick={(e) => {
            HapticFeedback();
            if (!recipeHasGroceriesList) {
              addToGroceries({
                users: [userId],
                recipe: recipeId
              });
              sendUserActivity({
                type: "add_recipe_to_shopping_list",
                recipe: recipeId
              });
            } else {
              removeFromGroceries(recipeHasGroceriesList.shoppingListId);
              sendUserActivity({
                type: "remove_recipe_from_shopping_list",
                recipe: recipeId
              });
            }
          }}
          className="mb-0"
          loading={
            (addToGroceriesLoading ||
              removeFromGroceriesLoading ||
              recipeHasGroceriesListLoading) &&
            "true"
          }
        >
          <ShoppingBasketIcon />
          {recipeHasGroceriesList
            ? "Remove from groceries list"
            : "Add to groceries list"}
        </StyledActionButton>
      )}
      <StyledActionButton
        onClick={(e) => {
          HapticFeedback();

          if (!favoriteRecipe) {
            addToFavorites({
              user: userId,
              recipe: recipeId
            });
            sendUserActivity({
              type: "add_recipe_to_favorites",
              recipe: recipeId
            });
          } else {
            removeFavorite({ id: favoriteRecipe.favoriteId });
            sendUserActivity({
              type: "remove_recipe_from_favorites",
              recipe: recipeId
            });
          }
        }}
        className="mb-0"
        style={{ border: "0" }}
        loading={
          (addToFavoritesLoading ||
            removeFavoriteLoading ||
            favoriteRecipeLoading) &&
          "true"
        }
      >
        <BookmarkSolid />
        {favoriteRecipe ? "Remove from favorites" : "Add to favorites"}
      </StyledActionButton>
    </BaseModal>
  );
});

export default RecipeOptions;
