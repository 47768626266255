import styled from "styled-components";

const StyledFooter = styled.div`
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #ffffff;
  padding-bottom: var(--safe-area-inset-bottom);

  /* &:after {
    content: "";
    height: 1px;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 3.5%;
    right: 3.5%;
    background-color: #dbdbe7;
  } */

  ul {
    position: relative;
    z-index: 2;
    height: 77px;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    li {
      width: 25%;
      display: flex;
      justify-content: center;

      button {
        padding: 0;

        a {
          display: block;
          padding: 10px;

          svg {
            display: block;
          }

          &.active {
            border-radius: 6px;
            background-color: #65c18c;

            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
`;

export default StyledFooter;
