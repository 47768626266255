import styled, { css } from "styled-components";

const StyledIncrementor = styled.div`
  width: 100%;
  min-width: 90px;
  min-height: 55px;
  margin: 0 0 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Nanum Gothic", sans-serif;
  font-size: 20px;
  text-align: center;
  color: #454545;
  border-radius: 28px;
  background-color: #f5f7fb;

  span {
    font-size: 15px;
  }

  .control {
    width: 30px;
    height: 30px;
    cursor: pointer;
    outline: 0;
    border: 0;
    padding: 0;
    line-height: 30px;
    text-align: center;
    background-color: transparent;
    transition: all 200ms ease-in-out;
    border-radius: 100%;

    svg {
      width: auto;
      height: 14px;
      fill: #454545;
    }

    &:active {
      background-color: #454545;

      svg {
        fill: #fff;
      }
    }

    &:disabled {
      background-color: #d4d4d4;

      svg {
        fill: #a0a0a0;
      }
    }
  }
`;

export default StyledIncrementor;
