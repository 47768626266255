import styled, { css } from "styled-components";
import ValidFieldIcon from "../Assets/valid-field.svg";
import InValidFieldIcon from "../Assets/invalid-field.svg";

const StyledForm = styled.div`
  /* > *:last-child {
    margin: 0;
  } */
`;

const StyledFakeField = styled.div`
  position: relative;
  margin: 0 0 20px;
  width: 100%;
  min-height: 55px;
  font-family: "Nanum Gothic", sans-serif;
  font-size: 15px;
  line-height: 32px;
  text-align: left;
  color: #adb3bf;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #dbdbe7;
  padding: 0 2px;
  border-radius: 0;
  background-color: transparent;

  ${({ outlined }) =>
    outlined &&
    css`
      padding: 10px 10px 5px;
      border-radius: 6px;
      border: 1px solid #dbdbe7;
    `}
`;

const StyledFormGroup = styled.div`
  position: relative;
  margin: 0 0 20px;

  input,
  select {
    width: 100%;
    height: 55px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    color: #404852;
    outline: 0;
    border: 0;
    border-bottom: 1px solid #dbdbe7;
    padding: 0 2px;
    border-radius: 0;
    background-color: transparent;

    &::-ms-expand {
      display: none;
    }

    &::-webkit-input-placeholder {
      color: #adb3bf;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: #adb3bf;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #adb3bf;
      opacity: 1;
    }

    &:-moz-placeholder {
      color: #adb3bf;
      opacity: 1;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .valid-field {
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center right 2px;
    background-image: url(${ValidFieldIcon});
  }

  .invalid-field {
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center right 2px;
    background-image: url(${InValidFieldIcon});
  }

  .spinner {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 2.4px;
  }

  ${({ outlined }) =>
    outlined &&
    css`
      input,
      select {
        padding: 0 20px;
        border-radius: 6px;
        border: 1px solid #dbdbe7;
      }
      .valid-field,
      .invalid-field {
        background-position: center right 20px;
      }
    `}
`;

const StyledFormGroupSwitch = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  border-bottom: 1px solid #dbdbe7;
  padding: 0 2px;

  label {
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    color: #404852;
  }

  div {
    margin-left: auto;
  }
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;

  label {
    position: relative;
    cursor: pointer;
  }

  label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #0079bf;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  &:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export {
  StyledForm,
  StyledFormGroup,
  StyledFormGroupSwitch,
  StyledFakeField,
  StyledCheckbox
};
