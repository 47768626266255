import NiceModal from "@ebay/nice-modal-react";
import InfoModal from "./InfoModal";
import RecipeOptions from "./RecipeOptions";
import AddRecipeToPlanner from "./AddRecipeToPlanner";
import PlannerConfirmation from "./PlannerConfirmation";
import Filter from "./Filter";
import SaveFilter from "./SaveFilter";

NiceModal.register("info-modal", InfoModal);
NiceModal.register("recipe-options-modal", RecipeOptions);
NiceModal.register("add-recipe-to-planner-modal", AddRecipeToPlanner);
NiceModal.register("planner-confirmation-modal", PlannerConfirmation);
NiceModal.register("filter-modal", Filter);
NiceModal.register("save-filter", SaveFilter);

export {
  InfoModal,
  RecipeOptions,
  AddRecipeToPlanner,
  PlannerConfirmation,
  SaveFilter
};

export default NiceModal;
