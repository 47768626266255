import styled from "styled-components";

const StyledSignIn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  padding-top: calc(34px + var(--safe-area-inset-top));
  padding-bottom: calc(34px + var(--safe-area-inset-bottom));

  form {
    display: flex;
    flex-direction: column;
    /* margin-top: auto; */

    .forgot-password {
      font-size: 13px;
      margin: 0 0 10px auto;
      display: flex;
    }

    .signin-action {
      text-align: center;
      font-size: 16px;
      margin: 20px 0 10px;

      a {
        font-size: 16px;
      }
    }
  }
`;

export default StyledSignIn;
