import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useHasRoles } from "react-simple-rbac";
import { ButtonBase } from "@mui/material";
import { useModal } from "@ebay/nice-modal-react";
import { InfoModal } from "../";
import { PostMessage, HapticFeedback } from "../../utils";
import {
  StyledButton,
  StyledButtonDark,
  StyledMealList,
  StyledProgress,
  StyledButtonIcon
} from "../../Style";
import {
  setCookingSessionsDialogViewed,
  selectCookingSessionDialogViewd
} from "../../Slices/generalSlice";
import { useSendUserActivityMutation } from "../../Slices/apiUserActivitySlice";
import { selectCurrentUser } from "../../Slices/authSlice";
import {
  useLazyGetCookingSessionsQuery,
  useUpdateCookingSessionMutation
} from "../../Slices/apiCookingSessionSlice";
import { ReactComponent as TrashIcon } from "../../Assets/trash-alt.svg";

const ActiveCookingSessionsDialogContent = ({
  cookingSessions,
  cookingSessionsModal,
  navigate
}) => {
  const isProUser = useHasRoles(["Pro User"]);

  const abandonCookingSessionModal = useModal(InfoModal);

  const [selectedCookingSession, setSelectedCookingSession] = useState(null);

  const [sendUserActivity] = useSendUserActivityMutation();

  const [updateCookingSession] = useUpdateCookingSessionMutation();

  const abandonCookingSession = (sessionId, recipeId) => {
    HapticFeedback();
    abandonCookingSessionModal.show({
      heading: "Abandon cooking session?",
      description: "Are you sure you want to abandon this cooking session?",
      dismissLabel: "No",
      submitLabel: "Yes",
      onSubmit: () => onAbandonCookingSession(sessionId, recipeId)
    });
  };

  const onAbandonCookingSession = (sessionId, recipeId) => {
    updateCookingSession({
      id: sessionId,
      data: {
        abandoned: true
      }
    });
    sendUserActivity({
      type: "abandoned_active_cookoing_session",
      recipe: recipeId
    });
    abandonCookingSessionModal.remove();
  };

  return (
    <>
      <StyledMealList
        style={{
          maxHeight: "40vh",
          overflow: "auto"
        }}
      >
        {cookingSessions &&
          cookingSessions.length &&
          cookingSessions.map((session, key) => {
            const {
              id: sessionId,
              currentStep,
              recipe: {
                id,
                title,
                steps,
                cover: {
                  formats: {
                    large = null,
                    medium = null,
                    small = null,
                    thumbnail = null
                  } = {}
                } = {}
              } = {}
            } = session;

            return (
              <li key={key}>
                <ButtonBase
                  style={
                    selectedCookingSession?.id === sessionId
                      ? {
                          width: "100%",
                          padding: "5px",
                          border: "1px solid #65c18c",
                          borderRadius: "8px"
                        }
                      : {
                          width: "100%",
                          padding: "5px",
                          border: "1px solid transparent"
                        }
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    HapticFeedback();
                    setSelectedCookingSession(session);
                  }}
                >
                  <div
                    className="recipe-image"
                    style={{
                      width: "74px",
                      height: "74px",
                      backgroundImage: `url(${process.env.REACT_APP_API_URL}${
                        small?.url ||
                        medium?.url ||
                        large?.url ||
                        thumbnail?.url
                      })`
                    }}
                  />
                  <div
                    className="recipe-info"
                    style={{ width: "calc(100% - 74px - 45px)" }}
                  >
                    <h2>
                      {title}
                      <br />
                      <small>
                        ( {steps.length - currentStep} remaining steps )
                      </small>
                    </h2>
                    <div className="recipe-meta" style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <StyledProgress>
                          <div className="progress-container">
                            <div
                              className="progress love"
                              style={{
                                width: (currentStep / steps.length) * 100 + "%"
                              }}
                            ></div>
                          </div>
                        </StyledProgress>
                      </div>
                    </div>
                  </div>
                  <StyledButtonIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      abandonCookingSession(sessionId, id);
                    }}
                    className="ml-a"
                  >
                    <TrashIcon />
                  </StyledButtonIcon>
                </ButtonBase>
              </li>
            );
          })}
      </StyledMealList>
      <div className="columns is-mobile mt-30">
        <div className="column">
          <StyledButtonDark
            onClick={(e) => {
              e.stopPropagation();
              HapticFeedback();
              sendUserActivity({
                type: "dismissed_active_cooking_dialog"
              });
              cookingSessionsModal.remove();
            }}
            className="mb-0"
          >
            Dismiss
          </StyledButtonDark>
        </div>
        {selectedCookingSession && (
          <div className="column">
            <StyledButton
              onClick={(e) => {
                e.stopPropagation();
                HapticFeedback();
                isProUser &&
                  PostMessage({
                    type: "requestVUIPermissions"
                  });
                sendUserActivity({
                  type: "resumed_active_cookoing_session",
                  recipe: selectedCookingSession?.recipe?.id
                });
                navigate(
                  `/recipes/${selectedCookingSession?.recipe?.id}/steps`
                );
                cookingSessionsModal.remove();
              }}
              className="mb-0"
            >
              Resume
            </StyledButton>
          </div>
        )}
      </div>
    </>
  );
};

const ActiveCookingSessionsDialog = () => {
  const navigate = useNavigate();

  const cookingSessionsModal = useModal("info-modal");

  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const cookingSessionsDialogViewed = useSelector(
    selectCookingSessionDialogViewd
  );

  const isProUser = useHasRoles(["Pro User"]);

  const [
    getCookingSessions,
    {
      data: cookingSessions,
      isFetching: cookingSessionsIsFetching,
      isLoading: cookingSessionsIsLoading,
      isSuccess: cookingSessionsIsSuccess,
      isError: cookingSessionsIsError
    }
  ] = useLazyGetCookingSessionsQuery();

  useEffect(() => {
    if (user?.id && !cookingSessionsDialogViewed && isProUser) {
      getCookingSessions();
    }
  }, [user, cookingSessionsDialogViewed]);

  useEffect(() => {
    if (cookingSessionsIsSuccess && cookingSessions && cookingSessions.length) {
      HapticFeedback("impactHeavy");
      cookingSessionsModal.show({
        heading: "Active Cooking Sessions",
        description:
          "Select a Recipe from the list bellow in order to resume cooking.",
        content: (
          <ActiveCookingSessionsDialogContent
            cookingSessions={cookingSessions}
            cookingSessionsModal={cookingSessionsModal}
            navigate={navigate}
          />
        ),
        hideActions: true,
        hideDismiss: true,
        hideSubmit: true,
        dismissLabel: "Dismiss",
        submitLabel: "Resume"
      });
      dispatch(setCookingSessionsDialogViewed(true));
    }
  }, [cookingSessionsIsSuccess, cookingSessions]);

  return null;
};

export default ActiveCookingSessionsDialog;
