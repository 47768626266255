import React from "react";
import StyledModal from "./style";
import { StyledButton, StyledButtonDark, StyledSpinner } from "../../Style";
import Bounce from "animate-css-styled-components/lib/Bounce";

const BaseModal = ({
  children,
  visible,
  className,
  wrapperClassName,
  fullHeight,
  type,
  hideWrapper,
  animation,
  onDismiss,
  onSubmit,
  loading,
  heading,
  description,
  hideDismiss,
  dismissLabel,
  hideSubmit,
  submitLabel,
  hideActions,
  actions
}) => {
  const Content = (
    <>
      {heading && <h2>{heading}</h2>}
      {description && <p>{description}</p>}
      {children}
      {!actions && !hideActions ? (
        <>
          <div className="columns is-mobile mt-30">
            {!hideDismiss && (
              <div className="column">
                <StyledButtonDark
                  onClick={(e) => onDismiss(e)}
                  className="mb-0"
                  loading={(loading === "onDismiss").toString()}
                >
                  {dismissLabel ? dismissLabel : "Dismiss"}
                </StyledButtonDark>
              </div>
            )}
            {!hideSubmit && (
              <div className="column">
                <StyledButton
                  onClick={(e) => onSubmit(e)}
                  className="mb-0"
                  loading={(loading === "onSubmit").toString()}
                >
                  {submitLabel ? submitLabel : "Submit"}
                </StyledButton>
              </div>
            )}
          </div>
        </>
      ) : (
        actions
      )}
    </>
  );

  const Wrapper = !!hideWrapper ? (
    { Content }
  ) : (
    <div
      className={`dialog-card ${wrapperClassName || ""}`}
      onClick={(e) => e.stopPropagation()}
      style={
        type === "view"
          ? {
              overflow: "hidden",
              overflowY: "auto",
              padding: "14px",
              paddingTop: "calc(14px + var(--safe-area-inset-top))",
              paddingBottom: "calc(77px + var(--safe-area-inset-bottom))",
              borderRadius: 0
            }
          : {}
      }
    >
      {Content}
      {loading === "modal" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            backgroundColor: "#fff"
          }}
        >
          <StyledSpinner
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              fontSize: "4px",
              borderLeftColor: "#65c18c"
            }}
          />
        </div>
      )}
    </div>
  );

  return visible ? (
    <StyledModal
      className={`global-dialog ${className || ""}`}
      onClick={(e) => onDismiss(e)}
      style={type === "view" ? { padding: "0" } : {}}
    >
      {animation ? (
        <animation
          className="dialog-animation"
          duration="0.2s"
          style={{
            width: "100%",
            height: fullHeight ? "100%" : "auto"
          }}
        >
          {Wrapper}
        </animation>
      ) : (
        <Bounce
          className="dialog-animation"
          duration="0.2s"
          style={{
            width: "100%",
            height: fullHeight ? "100%" : "auto"
          }}
        >
          {Wrapper}
        </Bounce>
      )}
    </StyledModal>
  ) : null;
};

export default BaseModal;
